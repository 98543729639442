import NoData from 'components/NoData'
import Skeleton from 'components/Skeleton'
import Title from 'components/Title'
import { privateRequest } from 'config/axios.config'
import PlayCard from 'features/play/playCard'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export default function PlayPage() {
  const { data, isLoading } = useQuery<Game[], Error>('games', async () => {
    try {
      const res = await privateRequest.get('user/games')
      return res.data.games
    } catch (error) {
      errorHandler(error)
    }
  })

  return (
    <div className='card'>
      <Title>Games</Title>
      <div className='mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
        {isLoading
          ? Array(3)
              .fill(0)
              .map((_, key) => (
                <div key={key}>
                  <Skeleton className='h-72 w-full' />
                  <Skeleton className='h-4 w-3/4 mt-3' />
                </div>
              ))
          : data?.map((row: any) => <PlayCard key={row.id} game={row} />)}
      </div>
      {!isLoading && !data?.length ? <NoData /> : null}
    </div>
  )
}
