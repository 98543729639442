import clsx from 'clsx'

const Thinking = ({ className = '' }: { className?: string }) => {
  return (
    <div className={clsx('loading-icon-container', className)}>
      <div className='loading-icon'></div>
      <div className='loading-icon'></div>
      <div className='loading-icon'></div>
    </div>
  )
}

export default Thinking
