import BreadCrumb from 'components/BreadCrumb'
import Devider from 'components/Devider'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { SyntheticEvent, useState } from 'react'

export default function ChangeEmailPage() {
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false)

  const submitHandler = (e: SyntheticEvent) => {
    e.preventDefault()
    setIsOtpSent(!isOtpSent)
  }

  return (
    <div className='card'>
      <BreadCrumb prevPage='/security' title='Change Email' />
      <Devider />

      <form onSubmit={submitHandler}>
        {isOtpSent ? (
          <Input placeholder='*****' label='Enter 6 digit OTP' />
        ) : (
          <Input placeholder='youremail@example.com' label='Enter New Email' />
        )}
        <div className='mt-6 flex justify-end'>
          <Button className='!px-10'>{isOtpSent ? 'Submit' : 'NEXT'}</Button>
        </div>
      </form>
    </div>
  )
}
