import staking from 'images/staking.png';

export default function StakingPage() {

  return (
    <div className='card text-center'>
      <img src={staking} alt="" className='mx-auto mb-10' />
      <p className='text-[#7A7A7A] text-lg leading-9 max-w-6xl mx-auto'>{'Earn passive income through RME  Fincare\'s staking program. By locking up your digital assets on our platform, you can earn interest and rewards through newly minted tokens. Our staking system is designed to provide users with a secure and profitable way to grow their investments. Get ready to uncover the remarkable advantages that are eagerly awaiting you! Prepare yourself for some electrifying news that will leave you astonished. Brace yourself to be blown away!'}</p>
    </div>
  )
}
