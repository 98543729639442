import { BsYoutube } from 'react-icons/bs'

import { appUrl } from 'utils/url'

export default function MarketingToolsCard({ tool }: { tool: Tool }) {
  return (
    <div className='p-3 block border border-[#D2D7E1] rounded-md'>
      <div className='relative mb-3 rounded-sm overflow-hidden'>
        {tool.thumbnail_path && (
          <img className='h-full w-full object-cover' src={appUrl + tool.thumbnail_path} alt='' />
        )}
        <div className='absolute left-0 top-0 h-full w-full bg-dark/5'></div>
        {/* <div className='absolute right-0 bottom-2 bg-[#0B2628]/50 rounded-l-full text-white text-xs font-medium px-3'>
          01.36
        </div> */}
        {tool.type === 'video' && (
          <BsYoutube
            className='text-white absolute left-1/2
         top-1/2 -translate-x-1/2 -translate-y-1/2'
            size={50}
          />
        )}
      </div>
      <h3 className='text-base font-medium text-dark'>{tool.title}</h3>
      <a
        href={appUrl + tool.file_path}
        target='_blank'
        rel='noreferrer'
        download
        className='btn btn-primary btn-sm mt-2'
      >
        Download
      </a>
    </div>
  )
}
