import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Modal from 'components/modal'
import { IoExpand } from 'react-icons/io5'

type Props = {
  game: Game
}

export default NiceModal.create(({ game: { title, game_url } }: Props) => {
  // Use a hook to manage the modal state
  const modal = useModal()

  const fullscreenView = () => {
    const element = document.getElementById('gameframe')
    if (!document.fullscreenElement) {
      element?.requestFullscreen()
    }
  }

  return (
    <Modal
      title={
        <>
          {title}{' '}
          <button
            title='Full Screen'
            className='ml-auto inline-flex text-slate-600 bg-primary-shade rounded-full h-7 w-7'
            onClick={fullscreenView}
          >
            <IoExpand className='m-auto' size={20} />
          </button>
        </>
      }
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='!w-[calc(100%-50px)] h-[calc(100%-50px)]'
    >
      <iframe
        id='gameframe'
        className='w-full h-[calc(100%-70px)]'
        title={title}
        src={game_url}
      ></iframe>
    </Modal>
  )
})
