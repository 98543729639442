import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Select from 'components/form/Select'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { LoaderIcon } from 'react-hot-toast'
import { BsCheck2Circle } from 'react-icons/bs'
import QRCode from 'react-qr-code'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export default NiceModal.create(({ data }: { data: UpgradeMembership }) => {
  const modal = useModal()
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<Option>({
    label: 'usdc',
    value: data?.charge.addresses.usdc,
  })
  const statusQuery = useQuery(
    'get-status',
    async () => {
      try {
        const res = await privateRequest.get('user/get-membership-status')
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
    { enabled: true, refetchInterval: 5000 },
  )

  const transactionStatus = statusQuery.data?.status || data.transaction.status

  return (
    <Modal
      title='Upgrade Plan'
      visible={modal.visible}
      onCancel={() => modal.hide()}
      className='max-w-2xl'
    >
      {transactionStatus !== 'confirmed' && transactionStatus !== 'pending' && (
        <>
          <Select
            containerClass='w-full uppercase'
            value={{
              label: selectedPaymentMethod.label.toUpperCase(),
              value: selectedPaymentMethod.value,
            }}
            label='Select Payment Method'
            onChange={(e) => setSelectedPaymentMethod(e.target.value)}
            options={Object.keys(data?.charge.addresses).map((key) => ({
              label: key,
              value: data?.charge.addresses[key as keyof Addresses],
            }))}
          />
          <div className='text-center'>
            <QRCode className='mx-auto my-10' value={selectedPaymentMethod.value} />
            <h3 className='text-2xl font-semibold my-4'>
              {data.charge.pricing[selectedPaymentMethod.label]?.amount}{' '}
              <span className='text-gray'>{selectedPaymentMethod.label.toUpperCase()}</span>
            </h3>
            <p className='text-[#888E9D] font-medium text-sm mb-6'>
              Send {data.charge.pricing[selectedPaymentMethod.label]?.amount}{' '}
              {selectedPaymentMethod.label.toUpperCase()} (in ONE payment) to: <br /> don’t include
              transaction fee in this amount
            </p>

            <div className='font-medium text-lg text-primary'>{selectedPaymentMethod.value}</div>
          </div>
        </>
      )}
      <Button variant='outlined' color='default' size='md' className='py-6 mt-10' fullWidth>
        {transactionStatus === 'new' && (
          <>
            <LoaderIcon className='w-6 h-6' /> Awaiting Payment from you
          </>
        )}
        {transactionStatus === 'pending' && (
          <>
            <LoaderIcon className='w-6 h-6' /> Your payment is being processed
          </>
        )}
        {transactionStatus === 'confirmed' && (
          <>
            <BsCheck2Circle size={23} /> Payment has been confirmed
          </>
        )}
      </Button>
      {transactionStatus === 'confirmed' && (
        <p className='p-10'>
          Thank you for your payment! Your transaction has been successfully confirmed. We
          appreciate your trust and support, and we are dedicated to providing you with the best
          possible experience. If you have any questions or need assistance, please do not hesitate
          to reach out to our support team. Enjoy the benefits of your upgraded plan!
        </p>
      )}
      {transactionStatus === 'pending' && (
        <p className='p-10'>
          Your payment is currently being processed. This may take a few moments, so please be
          patient. You may continue using the application or close this modal if you wish. Once your
          payment has been successfully processed, you will receive a confirmation. If you have any
          questions or concerns, feel free to reach out to our support team. Thank you for your
          patience and understanding!
        </p>
      )}
      {transactionStatus === 'new' && (
        <p className='text-sm text-[#888E9D] text-center mt-7'>
          If you send any other cryptocurrency than the indicated one, payment system will ignore
          it!
        </p>
      )}
    </Modal>
  )
})
