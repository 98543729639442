import Devider from 'components/Devider'
import Title from 'components/Title'
import Analytics from 'features/dashboard/Analytics'
import LastActivity from 'features/dashboard/LastActivity'
import PlanInfo from 'features/dashboard/PlanInfo'
import Refer from 'features/dashboard/Refer'

export default function Dashboard() {
  return (
    <div>
      <Analytics />
      <Devider />
      <Title className='mb-4'>Latest Activity</Title>
      <div className='grid grid-cols-1 xl:grid-cols-3 gap-5'>
        <div className='col-span-2'>
          <LastActivity />
        </div>
        <div>
          <Refer />
          <PlanInfo />
        </div>
      </div>
    </div>
  )
}
