import clsx from 'clsx'

type Props = {
  options: TabOption[]
  selected: TabOption
  selectHandler: (selected: TabOption) => void
}

export default function Tabs({ options, selected, selectHandler }: Props) {
  return (
    <ul className='inline-flex flex-wrap rounded-lg overflow-hidden'>
      {options?.map((option) => (
        <li
          onClick={() => selectHandler(option)}
          className={clsx(
            'h-14 grid place-items-center px-8 font-semibold text-sm cursor-pointer',
            selected.value === option.value ? 'bg-[#1C366A] text-white' : 'bg-[#D2D7E1] text-dark',
          )}
          key={option.value}
        >
          {option.label}
        </li>
      ))}
    </ul>
  )
}
