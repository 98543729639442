import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

type WithdrawalPayload = {
  amount: string
  withdrawal_wallet_address: string
  chain: Option
  note: string
  withdrawal_crypto_currency: Option
}

type ErrorState = {
  amount?: string
  withdrawal_wallet_address?: string
  chain?: string
  note?: string
  withdrawal_crypto_currency?: string
}

export default NiceModal.create(({ balance }: { balance: string }) => {
  // Use a hook to manage the modal state
  const modal = useModal()

  const [form, setForm] = useState<WithdrawalPayload>({
    amount: '',
    withdrawal_wallet_address: '',
    chain: { label: 'BEP20', value: 'bep20' },
    note: '',
    withdrawal_crypto_currency: { label: 'USDT', value: 'usdt' },
  })

  const queryClient = useQueryClient()

  const [errors, setErrors] = useState<ErrorState>({})

  const withdrawMutation = useMutation<Message, any, FormData>(
    async (payload) => {
      try {
        const rest = await privateRequest.post('user/balance/withdraw', payload)
        return rest.data
      } catch (error: any) {
        if (error.response.data?.errors) {
          setErrors(error.response.data.errors)
        } else {
          modal.remove()
        }
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('get-balances')
        queryClient.invalidateQueries('get-transactions')
        modal.remove()
      },
    },
  )

  // set address from clipboard
  const pastAddress = () => {
    navigator.clipboard.readText().then((text) => {
      setForm({ ...form, withdrawal_wallet_address: text })
    })
  }

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    if (errors[name as keyof ErrorState]) {
      setErrors({ ...errors, [name]: undefined })
    }

    if (name === 'amount') {
      const amount = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
      setForm({ ...form, [name]: amount })
    } else {
      setForm({ ...form, [name]: e.target.value })
    }
  }

  const selectHandler = (e: SelectElement) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('amount', form.amount.toString())
    formData.append('withdrawal_wallet_address', form.withdrawal_wallet_address)
    formData.append('chain', form.chain.value)
    formData.append('note', form.note)
    formData.append('withdrawal_crypto_currency', form.withdrawal_crypto_currency.value)
    toast.promise(withdrawMutation.mutateAsync(formData), {
      loading: 'Loading',
      success: (data) => data.message ?? 'Withdrawal successful',
      error: (error) => error.message ?? 'Something went wrong',
    })
  }

  return (
    <Modal
      title='Withdraw'
      visible={modal.visible}
      onCancel={() => modal.hide()}
      className='max-w-xl'
    >
      <form onSubmit={submitHandler} className='flex flex-col gap-6'>
        <Input
          label={
            <>
              Amount <span className='ml-auto text-dark'>Available Amount = {balance} USDT</span>
            </>
          }
          name='amount'
          value={form.amount}
          onChange={changeHandler}
          placeholder='Enter the amount'
          afterFix={
            <>
              <span className='text-gray'>USDT</span>{' '}
              <span
                className='cursor-pointer mx-3'
                onClick={() => {
                  setForm({ ...form, amount: balance })
                }}
              >
                Max
              </span>
            </>
          }
          helpText={errors.amount}
          error={!!errors.amount}
        />
        <Select
          name='chain'
          value={form.chain}
          onChange={selectHandler}
          containerClass='w-full'
          label='Network selection'
          placeholder='Select Chain'
          options={[{ label: 'BEP20', value: 'bep20' }]}
          helpText={errors.chain}
          error={!!errors.chain}
        />
        <Select
          name='withdrawal_crypto_currency'
          value={form.withdrawal_crypto_currency}
          onChange={selectHandler}
          containerClass='w-full'
          label='Crypto Currency'
          placeholder='Select Crypto Currency'
          options={[{ label: 'USDT', value: 'usdt' }]}
          helpText={errors.withdrawal_crypto_currency}
          error={!!errors.withdrawal_crypto_currency}
        />
        <Input
          value={form.withdrawal_wallet_address}
          onChange={changeHandler}
          name='withdrawal_wallet_address'
          label='Wallet Address'
          placeholder='**********'
          afterFix={
            <span className='cursor-pointer p-3 text-slate-600 font-medium' onClick={pastAddress}>
              Paste
            </span>
          }
          helpText={errors.withdrawal_wallet_address}
          error={!!errors.withdrawal_wallet_address}
        />
        <Input
          value={form.note}
          onChange={changeHandler}
          name='note'
          label='Note'
          placeholder='Enter the note'
          helpText={errors.note}
          error={!!errors.note}
        />

        <Button disabled={withdrawMutation.isLoading}>Withdraw</Button>
      </form>
    </Modal>
  )
})
