type Props = React.SVGProps<SVGSVGElement>
export default function RWalletIcon({ width = 41, height = 46, ...rest }: Props) {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox='0 0 41 46'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M40.8835 32.1634V13.6735C40.8835 12.896 40.6789 12.1323 40.2901 11.459C39.9014 10.7857 39.3423 10.2266 38.669 9.83795L22.6563 0.593238C21.9829 0.204596 21.2192 0 20.4417 0C19.6643 0 18.9006 0.204596 18.2272 0.593238L2.21451 9.83795C1.54123 10.2267 0.982135 10.7858 0.593407 11.459C0.20468 12.1323 1.97523e-05 12.8961 2.31599e-09 13.6735V32.1634C-2.51373e-05 32.9408 0.204614 33.7046 0.593346 34.3778C0.982078 35.0511 1.5412 35.6102 2.21451 35.9989L18.2272 45.2436C18.9006 45.6323 19.6643 45.8369 20.4417 45.8369C21.2192 45.8369 21.9829 45.6323 22.6563 45.2436L38.669 35.9989C39.3423 35.6102 39.9014 35.0511 40.2901 34.3778C40.6789 33.7046 40.8835 32.9408 40.8835 32.1634Z'
        fill='currentColor'
      />
      <path
        d='M16.5729 30.2692V14.9274H19.6631V30.2692H16.5729ZM20.8466 24.001V21.2614H21.1316C22.0667 21.2614 22.6657 20.8815 22.9287 20.1217C22.9872 19.9318 23.0164 19.7345 23.0164 19.53C23.0164 18.7556 22.6511 18.2369 21.9206 17.9739C21.6868 17.8862 21.4238 17.8424 21.1316 17.8424H20.8466V14.9274H21.3288C23.2429 14.9567 24.5871 15.6361 25.3615 16.9657C25.7268 17.594 25.9094 18.3026 25.9094 19.0916C25.8948 20.5966 25.398 21.7509 24.4191 22.5545C25.1496 23.0221 25.6172 23.8695 25.8218 25.0969C25.8364 25.243 25.851 25.3818 25.8656 25.5133L26.2382 30.2692H23.389L23.0602 26.368C22.9433 25.1991 22.6073 24.4759 22.0521 24.1983C21.7891 24.0814 21.4676 24.0156 21.0877 24.001H20.8466Z'
        fill='white'
      />
    </svg>
  )
}
