import Thinking from 'components/Thinking'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'
import { AuthContext } from 'context/AuthContext'
import UnAuthorizedView from 'features/ai-helper/UnAuthorizedView'
import ChatItemCard from 'features/tools/ChatItemCard'
import brandIcon from 'images/ai.png'
import { getAvatar } from 'queries/auth'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

// const suggestions = ['Write about Fincare', 'How rewards works']

type ChatItem = {
  ask?: string
  ai_response?: string
  type?: 'user' | 'ai'
}

type ChatHistoryItem = {
  id: number
  user_id: string
  user_message: string
  ai_response: string
  created_at: Date
  updated_at: Date
}

export default function AiHelperPage() {
  const { user } = useContext(AuthContext)
  const { data: avatar } = getAvatar()

  const [chatHistory, setChatHistory] = useState<ChatItem[]>([])

  const [question, setQuestion] = useState('')
  // const [showSuggestions, setShowSuggestions] = useState(true)

  const { data: history, isLoading: historyIsLoading } = useQuery<ChatHistoryItem[]>(
    'chat-history',
    async () => {
      try {
        const res = await privateRequest.get('user/ai/chat-history')
        return res.data.history
      } catch (error) {
        errorHandler(error)
      }
    },
  )

  const askMutation = useMutation<string, Error, FormData>(
    async (payload) => {
      try {
        const res = await privateRequest.post('user/ai/ask', payload)
        return res.data.response
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: (data) => {
        setChatHistory((prev) => [
          ...prev,
          {
            ai_response: data,
            type: 'ai',
          },
        ])
        setQuestion('')
      },
    },
  )

  const handleAsk = (prompt: string) => {
    if (user?.membership === 'Free') return

    const payload = new FormData()
    payload.append('message', prompt)

    setChatHistory((prev) => [
      ...prev,
      {
        ask: prompt,
        type: 'user',
      },
    ])
    setQuestion('')

    askMutation.mutate(payload)
  }

  // scroll to bottom on new message
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(scrollToBottom, [chatHistory, history])

  return (
    <div className=' flex flex-wrap'>
      {user?.membership === 'Free' ? (
        <UnAuthorizedView />
      ) : (
        <div className='flex flex-col gap-3 w-full p-7 bg-[#091D45] overflow-y-auto h-[calc(100vh-400px)]'>
          {historyIsLoading && <Thinking className='pt-20' />}
          {history?.map((chat, index) => (
            <Fragment key={index}>
              <div className='flex pb-2 pt-2'>
                {avatar ? (
                  <img
                    src={URL.createObjectURL(avatar)}
                    className='h-8 w-8 rounded-lg overflow-hidden object-cover bg-slate-500'
                    alt='Avatar'
                  />
                ) : (
                  <div className='h-8 w-8 rounded-lg overflow-hidden bg-gray-200'></div>
                )}

                <div className='pl-2 pt-1 text-white'>{user?.name}</div>
              </div>
              <ChatItemCard>
                <p
                  className='text-md text-dark flex-1 p-2 '
                  dangerouslySetInnerHTML={{
                    __html: chat.user_message ? chat.user_message.replace(/\n/g, '<br>') : '',
                  }}
                ></p>
              </ChatItemCard>
              <div className='flex pb-2 pt-2'>
                <img
                  src={brandIcon}
                  className='h-8 w-8 rounded-lg overflow-hidden object-contain'
                  alt='IMG'
                />
                <div className='pl-2 pt-1 text-white'>Ai Helper</div>
              </div>

              <ChatItemCard className='!bg-[#1C366A] '>
                <p
                  className='text-md text-white flex-1 leading-7 p-2'
                  dangerouslySetInnerHTML={{
                    __html: chat.ai_response ? chat.ai_response.replace(/\n/g, '<br>') : '',
                  }}
                ></p>
              </ChatItemCard>
            </Fragment>
          ))}
          {chatHistory.map((chat, index) => {
            if (chat.type === 'user') {
              return (
                <>
                  <div className='flex pb-2 pt-2'>
                    {avatar ? (
                      <img
                        src={URL.createObjectURL(avatar)}
                        className='h-8 w-8 rounded-lg overflow-hidden object-cover bg-slate-500'
                        alt='Avatar'
                      />
                    ) : (
                      <div className='h-8 w-8 rounded-lg overflow-hidden bg-gray-200'></div>
                    )}

                    <div className='pl-2 pt-1 text-white'>{user?.name}</div>
                  </div>
                  <ChatItemCard key={index}>
                    <p className='text-md text-dark flex-1'>{chat.ask}</p>
                  </ChatItemCard>
                </>
              )
            } else {
              return (
                <>
                  <div className='flex pb-2 pt-2'>
                    <img
                      src={brandIcon}
                      className='h-8 w-8 rounded-lg overflow-hidden object-contain'
                      alt='IMG'
                    />
                    <div className='pl-2 pt-1 text-white'>Ai Helper</div>
                  </div>
                  <ChatItemCard key={index} className='!bg-[#1C366A]'>
                    <p
                      className='text-md text-white flex-1 leading-7 p-2'
                      dangerouslySetInnerHTML={{
                        __html: chat.ai_response ? chat.ai_response.replace(/\n/g, '<br>') : '',
                      }}
                    ></p>
                  </ChatItemCard>
                </>
              )
            }
          })}
          {askMutation.isLoading && <Thinking />}
          <div ref={messagesEndRef} />
        </div>
      )}
      <div className='basis-full  bg-[#091D45] px-7 pb-7 mt-auto relative'>
        {/* {showSuggestions && !history?.length && !historyIsLoading && user?.membership !== 'Free' && (
          <div className='absolute left-0 bottom-full z-10 bg-[#091D45] px-7 w-full'>
            <p className='font-medium text-xs text-[#ACB3AE] mb-3'>Suggested:</p>
            <div className='flex gap-2 mb-4 flex-wrap'>
              {suggestions.map((suggestion, index) => (
                <Button
                  size='sm'
                  key={index}
                  onClick={() => {
                    setShowSuggestions(false)
                    setQuestion(suggestion)
                    handleAsk(suggestion)
                  }}
                >
                  {suggestion}
                </Button>
              ))}
            </div>
          </div>
        )} */}
        <Input
          disabled={user?.membership === 'Free'}
          className='mt-8'
          multiline
          rows={3}
          placeholder='Write Instruction'
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <Button
          disabled={user?.membership === 'Free'}
          onClick={() => (user?.membership === 'Free' ? {} : handleAsk(question))}
          fullWidth
          className='mt-4'
        >
          Ask AI Helper
        </Button>
      </div>
    </div>
  )
}
