import clsx from 'clsx'
import badgeAssociate from 'images/associate.png'
import badgeFree from 'images/free.png'

interface Props {
  data: NetworkNode
  updateSelectedNode: (data: any) => void
}

export default function NodeImage({ data, updateSelectedNode }: Props) {
  return (
    <div>
      <div
        className={clsx(
          'group relative cursor-pointer h-[60px] w-[60px] mx-auto bg-blue-300 grid place-items-center rounded-full',
        )}
        onClick={() => updateSelectedNode(data.id)}
      >
        <img
          src={data.membership === 'Free' ? badgeFree : badgeAssociate}
          className='h-14 w-14'
          alt=''
        />
        {data?.username && (
          <div className='opacity-0 invisible group-hover:!visible group-hover:!opacity-100 transition-all absolute -left-4 z-10 bottom-14 group-hover:bottom-16 bg-white border border-[#ddd] px-3 pt-2 pb-3 whitespace-nowrap text-left rounded-md w-max'>
            <span
              className='absolute left-8 bg-white border-r border-b border-[#ddd] h-4 w-4 rotate-45'
              style={{ top: 'calc(100% - 8px)' }}
            />
            <h4 className='text-lg text-dark capitalize font-medium mb-1'>{data.username}</h4>
            <p className='text-xs font-medium'>{data.membership} Member</p>
          </div>
        )}
        <div className='absolute top-full left-1/2 whitespace-nowrap text-xs z-10 -translate-y-2 -translate-x-1/2 mx-auto bg-primary-shade inline-block px-3 py-0.5 rounded-sm'>
          {data.username}
        </div>
      </div>
    </div>
  )
}
