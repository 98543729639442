export const securityTabs: Option[] = [
  {
    label: 'General',
    value: '/security/profile',
  },
  {
    label: 'Security',
    value: '/security',
  },
  // {
  //   label: 'Email Notifications',
  //   value: '/security/profile',
  // },
]
