import axios from 'axios'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'
import AuthLayout from 'layout/AuthLayout'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export default function ResetPasswordPage() {
  const [resetform, setResetForm] = useState({
    password: '',
    password_confirmation: '',
  })

  const { email, otp } = useLocation().state as { email: string; otp: string };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResetForm({
      ...resetform,
      [e.target.name]: e.target.value,
    })
  }

  const navigate = useNavigate()

  const resetPassword = useMutation<Message, Error, FormData>(
    async (payload) => {
      try {
        const res = await privateRequest.post('reset-password', payload)
        return res.data
      } catch (err) {
        if (axios.isAxiosError(err)) {
          // Checking if it's an AxiosError
          const serverError = err.response?.data;
          let errorMessage = 'An unknown error occurred'; // Default message

          if (serverError?.errors) {
            // Specific error message extraction
            // Assume 'password' is a primary field of interest
            if (serverError.errors.password) {
              errorMessage = serverError.errors.password[0]; // Display the first password error
            } else {
              // If no 'password' errors, display the first error of any field
              const firstErrorField = Object.keys(serverError.errors)[0];
              errorMessage = serverError.errors[firstErrorField][0];
            }
          } else if (serverError?.message) {
            // Fallback to a general message if no specific errors are provided
            errorMessage = serverError.message;
          }

          throw new Error(errorMessage);
        } else {
          // Handle non-Axios errors
          throw new Error('A network error occurred, please try again.');
        }
      }

    },
    {
      onSuccess: () => {
        navigate('/login')
      },
    },
  )


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const payload = new FormData()
    payload.append('otp', otp)
    payload.append('email', email)
    payload.append('password', resetform.password)
    payload.append('password_confirmation', resetform.password_confirmation)

    toast.promise(resetPassword.mutateAsync(payload), {
      loading: 'Resetting password...',
      success: (data) => data.message ?? 'Password reset!',
      error: (err) => err.message ?? 'Failed to reset password!',
    })
  }

  return (
    <AuthLayout
      className='!max-w-lg'
      title='Reset Password'
      description='Enter your new password.'
    >
      <form onSubmit={handleSubmit} className='flex flex-col gap-6'>
        <Input
          onChange={changeHandler}
          value={resetform.password}
          placeholder='Password'
          label='Password'
          name="password"
          type="password"
        />
        <Input
          onChange={changeHandler}
          value={resetform.password_confirmation}
          placeholder='Confirm Password'
          label='Confirm Password'
          name="password_confirmation"
          type="password"
        />
        <Button fullWidth>
          Submit
        </Button>
      </form>
      <p className='mt-12 text-gray text-lg font-medium text-center'>
        Remember your password?{' '}
        <Link to='/login' className='text-primary'>
          Login
        </Link>
      </p>
    </AuthLayout>
  )
}
