import { useModal } from '@ebay/nice-modal-react'
import { AuthContext } from 'context/AuthContext'
import exclusive from 'images/exclusive-badge.png'
import free from 'images/free-badge.png'
import premium from 'images/premium-badge.png'
import { useContext } from 'react'
import { BsLock } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { appUrl } from 'utils/url'
import UpgradeInfoCardModal from './UpgradeInfoCard.modal'

type Props = {
  book: Book
}

export default function BookCard({ book }: Props) {
  const { user } = useContext(AuthContext)

  const UpgradeInfoCard = useModal(UpgradeInfoCardModal)
  console.log('book', book)

  const isLocked =
    (book.tag_id === 2 && user?.membership === 'Free') || book.tag_id === 3

  return (
    <Link
      onClick={(e) => {
        if (isLocked) {
          e.preventDefault()
          UpgradeInfoCard.show()
        }
      }}
      to={`/academy/book/${book.id}`}
    >
      <div className='relative bg-slate-50 mb-3'>
        <img
          className='h-72 w-full object-cover rounded-md'
          src={appUrl + book.book_cover_image}
          alt=''
        />
        <div className='absolute left-2 bottom-2 inline-block drop-shadow-lg'>
          {book.tag_id === 1 && <img src={free} alt='' />}
          {book.tag_id === 2 && <img src={exclusive} alt='' />}
          {book.tag_id === 3 && <img src={premium} alt='' />}
        </div>
        {isLocked && (
          <div className='absolute flex items-center gap-1 right-2 bottom-3 py-1.5 bg-white rounded-full text-dark text-xs font-medium px-3 shadow-sm'>
            <BsLock />
            Locked
          </div>
        )}
      </div>
      <h6 className='text-xs font-medium text-gray mb-2'>by {book.book_author}</h6>
      <h3 className='text-base font-medium text-dark'>{book.book_title}</h3>
    </Link>
  )
}
