import { FC, memo } from 'react'
import { Tree } from 'react-organizational-chart'
import EmptyNode from './EmptyNode'
import NodeImage from './NodeImage'
import TreeList from './TreeList'

const AffiliateTree: FC<{
  data: NetworkNode
  updateSelectedNode: (id: string) => void
}> = ({ data, updateSelectedNode }) => {
  return (
    <div className='mt-5 lg:mt-0'>
      <Tree
        lineColor='#ccc'
        label={<NodeImage data={data} updateSelectedNode={updateSelectedNode} />}
      >
        {data.left_child ? (
          <TreeList
            key={data.left_child.id}
            data={data.left_child}
            updateSelectedNode={updateSelectedNode}
          />
        ) : (
          <EmptyNode />
        )}

        {data.right_child ? (
          <TreeList
            key={data.right_child.id}
            data={data.right_child}
            updateSelectedNode={updateSelectedNode}
          />
        ) : (
          <EmptyNode />
        )}
      </Tree>
    </div>
  )
}

export default memo(AffiliateTree)
