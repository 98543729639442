import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

type PayloadState = {
  receiver_username?: string
  amount?: string
  note?: string
}

export default NiceModal.create(({ balance }: { balance: string }) => {
  // Use a hook to manage the modal state
  const modal = useModal()

  const [form, setForm] = useState<PayloadState>({
    receiver_username: '',
    amount: '',
    note: '',
  })

  const [errors, setErrors] = useState<PayloadState>({})

  const queryClient = useQueryClient()

  const transferCreward = useMutation<Message, Error, FormData>(
    async (payload) => {
      try {
        const res = await privateRequest.post('user/balance/transfer/s-reward', payload)
        return res.data
      } catch (error: any) {
        if (error.response.data?.errors) {
          setErrors(error.response.data.errors)
        } else {
          modal.remove()
        }
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('get-balances')
        queryClient.invalidateQueries('get-transactions')
        modal.remove()
      },
    },
  )

  const pastUsername = () => {
    navigator.clipboard.readText().then((text) => {
      setForm({ ...form, receiver_username: text })
    })
  }

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errors[e.target.name as keyof PayloadState]) setErrors({ ...errors, [e.target.name]: '' })
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('receiver_username', form.receiver_username as string)
    formData.append('amount', form.amount as string)
    formData.append('note', form.note as string)

    toast.promise(transferCreward.mutateAsync(formData), {
      loading: 'Transferring...',
      success: (res) => res.message ?? 'Transfered successfully',
      error: (err) => err.message ?? 'Something went wrong',
    })
  }

  return (
    <Modal
      title='Transfer s-rewards'
      visible={modal.visible}
      onCancel={() => modal.hide()}
      className='max-w-xl'
    >
      <form onSubmit={submitHandler} className='flex flex-col gap-6'>
        <Input
          label={
            <>
              Amount <span className='ml-auto text-dark'>Available Amount = {balance} Credit</span>
            </>
          }
          placeholder='Enter the amount'
          afterFix={
            <span
              className='cursor-pointer mx-3'
              onClick={() => setForm({ ...form, amount: balance })}
            >
              Max
            </span>
          }
          name='amount'
          value={form.amount}
          onChange={changeHandler}
          helpText={errors.amount}
          error={!!errors.amount}
        />
        <Input
          value={form.receiver_username}
          onChange={changeHandler}
          name='receiver_username'
          label='Username'
          placeholder='Enter username'
          afterFix={
            <span className='cursor-pointer p-3 text-slate-600 font-medium' onClick={pastUsername}>
              Paste
            </span>
          }
          helpText={errors.receiver_username}
          error={!!errors.receiver_username}
        />
        <Button disabled={transferCreward.isLoading}>Transfer</Button>
      </form>
    </Modal>
  )
})
