import { privateRequest } from 'config/axios.config'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import Alert from 'components/Alert'
import BreadCrumb from 'components/BreadCrumb'
import Devider from 'components/Devider'
import { Loader } from 'components/Loader'
import { getVideoId } from 'utils'
import { errorHandler } from 'utils/errorHandler'

export default function SingleCoursePage() {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, isError, error } = useQuery<Course, Error>(
    ['course', id],
    async () => {
      try {
        const res = await privateRequest.get(`user/course/${id}`)
        return res.data.courses
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      enabled: id !== undefined,
    },
  )

  if (isLoading) return <Loader />

  if (isError) return <Alert message={error.message ?? 'Something went wrong!'} />
  const videoId = getVideoId(data?.video_url ?? '')

  return (
    <div className='card'>
      <BreadCrumb prevPage='/academy/courses' title='All Courses' />
      <Devider />
      <div className='relative'>
        <iframe
          className='w-full sm:h-96 lg:h-[500px] 2xl:h-[700px]'
          src={`https://streams.bitmovin.com/${videoId}/embed`}
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen
        ></iframe>
      </div>
      <h3 className='text-lg font-medium text-dark mt-4'>{data?.title}</h3>
      <h6 className='text-sm font-medium text-slate-600 mt-2'>{data?.description}</h6>
    </div>
  )
}
