import Button from 'components/form/Button'
import Tooltip from 'components/Tooltip'
import { PropsWithChildren } from 'react'

type Props = {
  title: string
  description: string
  buttonText: string
  link?: string
  isDisable?: boolean
  Icon: (props: IconBaseProps) => JSX.Element
}

export default function SecurityTab({
  buttonText,
  description,
  Icon,
  link,
  title,
  isDisable,
}: PropsWithChildren<Props>) {
  return (
    <div className='flex flex-wrap gap-3 md:gap-6 items-center mb-8 pb-8 border-b border-[#F3F3F3] last-of-type:border-none last-of-type:mb-0 last-of-type:pb-0'>
      <Button className='w-14 !p-0'>
        <Icon size={30} />
      </Button>
      <div className='mr-auto flex-1'>
        <h3 className='text-base text-dark font-semibold'>{title}</h3>
        <p className='font-medium text-xs text-gray mt-2 max-w-full overflow-hidden overflow-ellipsis'>
          {description}
        </p>
      </div>
      {isDisable ? (
        <Tooltip className='whitespace-nowrap' message='Coming Soon'>
          <Button to={link} color='default'>
            {buttonText}
          </Button>
        </Tooltip>
      ) : (
        <Button to={link} color='default'>
          {buttonText}
        </Button>
      )}
    </div>
  )
}
