import { useModal } from '@ebay/nice-modal-react'
import { AuthContext } from 'context/AuthContext'
import exclusive from 'images/exclusive-badge.png'
import free from 'images/free-badge.png'
import premium from 'images/premium-badge.png'
import { useContext } from 'react'
import { BsLock, BsYoutube } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { appUrl } from 'utils/url'
import UpgradeInfoCardModal from './UpgradeInfoCard.modal'

export default function CourseCard({ course }: { course: Course }) {
  const { user } = useContext(AuthContext)

  const UpgradeInfoCard = useModal(UpgradeInfoCardModal)

  const isLocked =
    !course.tag ||
    (course.tag.name === 'EXCLUSIVE' && user?.membership === 'Free') ||
    course.tag.name === 'PREMIUM'

  return (
    <Link
      onClick={(e) => {
        if (isLocked) {
          e.preventDefault()
          UpgradeInfoCard.show()
        }
      }}
      className='border border-primary-shade p-5'
      to={`/academy/course/${course.id}`}
    >
      <div className='relative mb-3'>
        <img
          className='rounded-md overflow-hidden w-full'
          src={appUrl + course.video_thumbnail}
          alt=''
        />
        <BsYoutube
          className='text-white absolute left-1/2
         top-1/2 -translate-x-1/2 -translate-y-1/2'
          size={50}
        />
        <div className='absolute left-2 bottom-2 inline-block drop-shadow-lg'>
          {course.tag?.name === 'FREE' && <img src={free} alt='' />}
          {course.tag?.name === 'EXCLUSIVE' && <img src={exclusive} alt='' />}
          {course.tag?.name === 'PREMIUM' && <img src={premium} alt='' />}
        </div>
        {isLocked && (
          <div className='absolute flex items-center gap-1 right-2 bottom-3 py-1.5 bg-white rounded-full text-dark text-xs font-medium px-3 shadow-sm'>
            <BsLock />
            Locked
          </div>
        )}
      </div>
      <h3 className='text-base font-medium text-dark'>{course.title}</h3>
      <h6 className='text-[12px] font-medium text-gray mt-2'>
        {course.description?.substring(0, 70)} {course.description?.length >= 70 ? '...' : ''}
      </h6>
    </Link>
  )
}
