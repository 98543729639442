import CWalletIcon from 'components/icons/CWalletIcon'
import CoursesIcon from 'components/icons/CoursesIcon'
import EbookIcon from 'components/icons/EbookIcon'
import FincareIcon from 'components/icons/FincareIcon'
import PodcastIcon from 'components/icons/PodcastIcon'
import RWalletIcon from 'components/icons/RWalletIcon'
import { GiArtificialIntelligence, GiMusicalScore } from 'react-icons/gi'
import { HiOutlineAcademicCap } from 'react-icons/hi2'
import { IoIosGitNetwork } from 'react-icons/io'
import { IoGameControllerOutline, IoWalletOutline } from 'react-icons/io5'
import { MdOutlineSecurity } from 'react-icons/md'
import { RxDashboard } from 'react-icons/rx'
import { VscTools } from 'react-icons/vsc'

export const drawerLinks: DrawerLink[] = [
  {
    label: 'Dashboard',
    link: '/dashboard',
    icon: RxDashboard,
  },
  {
    label: 'Wallet',
    link: '/wallet',
    icon: IoWalletOutline,
    child: [
      {
        label: 'Rewards Wallet',
        link: '/wallet/r-wallet',
        icon: RWalletIcon,
      },
      {
        label: 'Credits Reward',
        link: '/wallet/c-reward',
        icon: CWalletIcon,
      },
      // {
      //   label: 'S-Reward',
      //   link: '/wallet/s-reward',
      //   icon: SWalletIcon,
      // },
    ],
  },
  {
    label: 'Network',
    link: '/network',
    icon: IoIosGitNetwork,
  },
  {
    label: 'Academy',
    link: '/academy',
    icon: HiOutlineAcademicCap,
    child: [
      {
        label: 'E-Books',
        link: '/academy/ebooks',
        icon: EbookIcon,
      },
      {
        label: 'Courses',
        link: '/academy/courses',
        icon: CoursesIcon,
      },
      {
        label: 'Podcasts',
        link: '/academy/podcasts',
        icon: PodcastIcon,
      },
    ],
  },
  {
    label: 'Play',
    link: '/play',
    icon: IoGameControllerOutline,
  },
  {
    label: 'Wellness',
    link: '/wellness',
    icon: FincareIcon,
    //   child: [
    //     {
    //       label: 'Exchange',
    //       link: '/fincare/exchange',
    //       icon: ExchangeIcon,
    //     },
    //     {
    //       label: 'Staking',
    //       link: '/fincare/staking',
    //       icon: StakingIcon,
    //     },
    //   ],
  },
  {
    label: 'Music',
    link: '/music',
    icon: GiMusicalScore,
  },
  {
    label: 'AI Helper',
    link: '/tools/ai-helper',
    icon: GiArtificialIntelligence,
  },
  {
    label: 'Marketing Tools',
    link: '/tools/marketing',
    icon: VscTools,
  },
  {
    label: 'Security',
    link: '/security/profile',
    icon: MdOutlineSecurity,
  },
]
