import axios from 'axios'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'
import AuthLayout from 'layout/AuthLayout'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export default function VerifyOtpPage() {
  const [otp, setOtp] = useState('')
  const email = useLocation().state as string;

  const navigate = useNavigate()


  const verifyOtp = useMutation<Message, Error, FormData>(
    async (payload) => {
      try {
        const res = await privateRequest.post('verify-otp', payload);
        return res.data;
      } catch (err) {
        if (axios.isAxiosError(err)) {
          // Checking if it's an AxiosError
          const serverError = err.response?.data;
          let errorMessage = 'An unknown error occurred'; // Default message

          if (serverError?.errors) {
            // Specific error message extraction
            // Assume 'password' is a primary field of interest
            if (serverError.errors.password) {
              errorMessage = serverError.errors.password[0]; // Display the first password error
            } else {
              // If no 'password' errors, display the first error of any field
              const firstErrorField = Object.keys(serverError.errors)[0];
              errorMessage = serverError.errors[firstErrorField][0];
            }
          } else if (serverError?.message) {
            // Fallback to a general message if no specific errors are provided
            errorMessage = serverError.message;
          }

          throw new Error(errorMessage);
        } else {
          // Handle non-Axios errors
          throw new Error('A network error occurred, please try again.');
        }
      }
    },
    {
      onSuccess: () => {
        navigate('/reset-password', { state: { email, otp } });
      },
    }
  );

  const resendOtp = useMutation<Message, Error, FormData>(async (payload) => {
    try {
      const res = await privateRequest.post('resend-otp', payload)
      return res.data
    } catch (err: any) {
      throw new Error(err.response.data.message.email?.[0] ?? err.response.data.message)
    }
  })

  const resendOtpHelper = () => {
    const payload = new FormData()
    payload.append('email', email)

    toast.promise(resendOtp.mutateAsync(payload), {
      loading: 'Sending otp...',
      success: (data) => data.message ?? 'otp sent!',
      error: (err) => err.message ?? 'Failed to send otp!',
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const payload = new FormData()
    payload.append('otp', otp)
    payload.append('email', email)

    toast.promise(verifyOtp.mutateAsync(payload), {
      loading: 'Sending otp...',
      success: (data) => data.message ?? 'otp sent!',
      error: (err) => err.message ?? 'Failed to send otp!',
    })
  }

  return (
    <AuthLayout
      className='!max-w-lg'
      title='Verify OTP'
      description='Enter your otp to verify'
    >
      <form onSubmit={handleSubmit}>

        <Input
          onChange={(e) => setOtp(e.target.value)}
          value={otp}
          placeholder='******'
          label='OTP'
        />
        <div className='flex justify-end mt-1'>
          <p
            onClick={resendOtpHelper}
            className='text-sm font-semibold text-gray cursor-pointer'
          >
            Resend OTP
          </p>
        </div>

        <Button disabled={resendOtp.isLoading || verifyOtp.isLoading} fullWidth className='mt-4'>
          Verify
        </Button>
      </form>
      <p className='mt-12 text-gray text-lg font-medium text-center'>
        Remember your password?{' '}
        <Link to='/login' className='text-primary'>
          Login
        </Link>
      </p>
    </AuthLayout>
  )
}
