import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'
import AuthLayout from 'layout/AuthLayout'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { errorHandler } from 'utils/errorHandler'

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState('')

  const navigate = useNavigate()

  const reqeustResetPassword = useMutation<Message, Error, FormData>(
    async (payload) => {
      try {
        const res = await privateRequest.post('forgot-password', payload)
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
    {
      onSuccess: () => {
        navigate('/verify-otp', { state: email })
      },
    },
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const payload = new FormData()
    payload.append('email', email)

    if (!email) return toast.error('Email is required!')

    toast.promise(reqeustResetPassword.mutateAsync(payload), {
      loading: 'Sending email...',
      success: (data) => data.message ?? 'Email sent!',
      error: (err) => err.message ?? 'Failed to send email!',
    })
  }

  return (
    <AuthLayout
      className='!max-w-lg'
      title='Reset your password'
      description='Enter your email to reset your password.'
    >
      <form onSubmit={handleSubmit}>
        <Input
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type='email'
          placeholder='Email'
        />
        <Button disabled={reqeustResetPassword.isLoading} fullWidth className='mt-4'>
          Submit
        </Button>
      </form>
      <p className='mt-12 text-gray text-lg font-medium text-center'>
        Remember your password?{' '}
        <Link to='/login' className='text-primary'>
          Login
        </Link>
      </p>
    </AuthLayout>
  )
}
