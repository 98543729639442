import { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import { AuthContext } from 'context/AuthContext'
import { getAvatar } from 'queries/auth'
import { useContext } from 'react'
import PreeUpgradePlanModal from './PreeUpgradePlan.modal'

export default function PlanInfo() {
  const { user } = useContext(AuthContext)
  const { data: avatar } = getAvatar()
  const preeUpgradePlan = useModal(PreeUpgradePlanModal)

  return (
    <div className='card mt-5'>
      <div className='flex items-center gap-4'>
        {avatar ? (
          <img
            src={URL.createObjectURL(avatar)}
            className='h-16 w-16 rounded-full overflow-hidden object-cover bg-slate-500'
            alt='IMG'
          />
        ) : (
          <div className='h-16 w-16 rounded-full overflow-hidden bg-slate-500 grid place-items-center text-white uppercase'>
            {user?.name?.split(' ')?.map((r) => r?.[0])}
          </div>
        )}
        <div>
          <h2 className='text-2xl font-medium mb-2'>{user?.name}</h2>
          <h5 className='text-secondary text-sm font-semibold'>{user?.membership} Member</h5>
        </div>
      </div>
      {user?.membership === 'Free' && (
        <Button
          onClick={() => preeUpgradePlan.show()}
          className='w-full !rounded-full mt-10'
          size='lg'
        >
          Upgrade
        </Button>
      )}
    </div>
  )
}
