import clsx from 'clsx'
import { AiOutlineInfoCircle } from 'react-icons/ai'

import Alert from 'components/Alert'
import Skeleton from 'components/Skeleton'
import Tooltip from 'components/Tooltip'
import { getStatistics } from 'queries/dashboard'
import AnalyticCard from './AnalyticCard'

import apsIcon from 'images/aps.png'
import cwalletIcon from 'images/c-reward.png'
import drbIcon from 'images/drb.png'
import dtbIcon from 'images/dtb.png'
import rwalletIcon from 'images/r-wallet.png'
import royalityRewardIcon from 'images/royality-reward.png'
import statusIcon from 'images/status-icon.png'
import teamIcon from 'images/team.png'

export default function Analytics() {
  const { data, isLoading, isError, error } = getStatistics()
  if (isError) {
    return <Alert message={error.message ?? 'Something went wrong!'} />
  }

  return (
    <div className='grid grid-cols-1 sm:grid-cols-3 2xl:grid-cols-4 gap-5'>
      {isLoading ? (
        <>
          {Array(9)
            .fill(0)
            .map((_, key) => (
              <div className='card' key={key}>
                <Skeleton className='h-6 mb-8 w-60' />
                <Skeleton className='h-6 w-32' />
              </div>
            ))}
        </>
      ) : (
        <>
          <AnalyticCard
            title={
              <>
                Rewards Wallet{' '}
                <Tooltip
                  position='bottom'
                  popupClass='w-[250px]'
                  className='ml-2 capitalize'
                  message={'R-Wallet is a convenient platform where you can credit and withdraw your bonus rewards from your rewards plan whenever you wish. You can also use R-Wallet to buy C-Rewards. If you need to transfer funds from your R-Wallet to an external wallet, don\'t worry! It\'s easy to do via the network. Just initiate a withdrawal, and your R-Wallet balance will be securely transferred to your chosen external wallet.'}
                >
                  <AiOutlineInfoCircle />
                </Tooltip>{' '}
                <img className='h-11 ml-auto' src={rwalletIcon} />
              </>
            }
          >
            <h2 className='text-3xl font-semibold mb-1'>{data?.r_wallet_balance}</h2>
            <p className='font-medium text-gray text-base'>≈ {data?.r_wallet_balance} USDT</p>
          </AnalyticCard>
          <AnalyticCard
            title={
              <>
                Credits Reward{' '}
                <Tooltip
                  position='bottom'
                  popupClass='w-[250px]'
                  className='ml-2 capitalize'
                  message={'Our Credit reward wallet is available to all users and can be used exclusively within our ecosystem for purchasing courses, gaming items, ecommerce products, and upgrading accounts. It\'s important to note that C-Rewards cannot be withdrawn, but they are transferable to other users, provided that the member is an associate.'}
                >
                  <AiOutlineInfoCircle />
                </Tooltip>{' '}
                <img className='h-11 ml-auto' src={cwalletIcon} />
              </>
            }
          >
            <h2 className='text-3xl font-semibold mb-1'>{data?.c_reward_balance}</h2>
            <p className='font-medium text-gray text-base'>≈ {data?.c_reward_balance} USDT</p>
          </AnalyticCard>
          {/* <AnalyticCard
            title={
              <>
                S-Reward{' '}
                <Tooltip
                  position='bottom'
                  popupClass='w-[250px]'
                  className='ml-2 capitalize'
                  message={'In order to receive Rewards, It is mandatory to upgrade as Associate. However, if someone signs up through your sponsor link, you can still qualify for S- Rewards and receive an immediate bonus of $1, even with a free membership'}
                >
                  <AiOutlineInfoCircle />
                </Tooltip>{' '}
                <img className='h-11 ml-auto' src={swalletIcon} />
              </>
            }
          >
            <h2 className='text-3xl font-semibold mb-1'>{data?.s_reward_balance}</h2>
            <p className='font-medium text-gray text-base'>≈ {data?.s_reward_balance} USDT</p>
          </AnalyticCard> */}
          <AnalyticCard
            title={
              <>
                Dual Team Rewards{' '}
                <Tooltip
                  position='bottom' className='ml-2 capitalize whitespace-nowrap' message={'DTB, also known as the Dual Team Bonus, consolidates all the rewards you\'ve obtained from the program. The vitality of the teams you\'ve formed within the network is crucial to this initiative.'}>
                  <AiOutlineInfoCircle />
                </Tooltip>
                <img className='h-11 ml-auto' src={dtbIcon} />
              </>
            }
          >
            <h2 className='text-3xl font-semibold mb-1'>{data?.dual_team_bonus}</h2>
            <p className='font-medium text-gray text-base'>≈ {data?.dual_team_bonus} USDT</p>
          </AnalyticCard>
          <AnalyticCard
            title={
              <>
                Direct Referral Rewards{' '}
                <Tooltip className='ml-2 capitalize whitespace-nowrap' message={'When you refer people to the program, the bonuses you earn from those referrals are combined to create the Direct Referral Bonus (DRB). The calibre of the teams you assemble will significantly impact the outcomes of your efforts.'}>
                  <AiOutlineInfoCircle />
                </Tooltip>
                <img className='h-11 ml-auto' src={drbIcon} />
              </>
            }
          >
            <h2 className='text-3xl font-semibold mb-1'>{data?.direct_referral}</h2>
            <p className='font-medium text-gray text-base'>≈ {data?.direct_referral} USDT</p>
          </AnalyticCard>
          <AnalyticCard
            title={
              <>
                My Team{' '}
                <Tooltip
                  popupClass='w-[250px]'
                  className='ml-2 capitalize'
                  message={'Rest assured that you have access to a complete roster of individuals that you have personally recommended to your network.'}
                >
                  <AiOutlineInfoCircle />
                </Tooltip>{' '}
                <img className='h-11 ml-auto' src={teamIcon} />
              </>
            }
          >
            <h2 className='text-3xl font-semibold mb-1'>{data?.my_network}</h2>
          </AnalyticCard>
          <AnalyticCard
            title={
              <>
                APS{' '}
                <Tooltip className='ml-2 capitalize whitespace-nowrap' message='Accumulated profit shares'>
                  <AiOutlineInfoCircle />
                </Tooltip>
                <img className='h-11 ml-auto' src={apsIcon} />
              </>
            }
          >
            <h2 className='text-3xl font-semibold'>{data?.aps}</h2>
          </AnalyticCard>
          <AnalyticCard
            title={
              <>
                Royalty Rewards{' '}
                <Tooltip
                  popupClass='w-[250px]'
                  className='ml-2 capitalize'
                  message={'Your Total Accumulated Royalty Rewards include earnings from both Sponsor Royalty Rewards and Dual Team Royalty Rewards. This comprehensive reward system boosts your income potential and helps to foster community growth.'}
                >
                  <AiOutlineInfoCircle />
                </Tooltip>
                <img className='h-11 ml-auto' src={royalityRewardIcon} />
              </>
            }
          >
            <h2 className='text-3xl font-semibold'>{data?.royalty_rewards}</h2>
          </AnalyticCard>
          <div className='bg-white p-6 rounded-md'>
            <h5 className={clsx('text-base font-medium mb-2 flex items-center flex-wrap')}>
              Status :
              <span
                className={clsx(
                  'ml-1',
                  data?.profit_share_status?.toLowerCase() == 'not qualified'
                    ? 'text-red-500'
                    : 'text-green-500',
                )}
              >
                {data?.profit_share_status}
              </span>
              <Tooltip
                popupClass='w-[250px]'
                className='ml-2 capitalize'
                message='Our Profit Share program enables you to receive a portion of the profits 
                  generated by RME. You can check your profit share status and receive regular updates'
              >
                <AiOutlineInfoCircle />
              </Tooltip>
              <img className='h-11 ml-auto' src={statusIcon} />
            </h5>
            <h5 className='text-base font-medium mb-2'>No. of Shares : {data?.profit_share}</h5>
            <h5 className='text-base font-medium mb-2'>
              Current Value : {data?.current_profit_share_value}
            </h5>
          </div>
        </>
      )}
    </div>
  )
}
