import { PropsWithChildren } from 'react'

type Props = {
  title: string | JSX.Element
  value?: number
}

export default function AnalyticCard({ title, children }: PropsWithChildren<Props>) {
  return (
    <div className='bg-white p-6 rounded-md'>
      <h5 className='mb-5 text-base uppercase flex items-center flex-wrap'>{title}</h5>
      {children}
    </div>
  )
}
