import { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import playGameModal from './playGame.modal'

type Props = {
  game: Game
}

export default function PlayCard({ game }: Props) {
  const playGame = useModal(playGameModal)
  return (
    <div className='border border-primary-shade p-3'>
      <img className='w-full object-cover mb-3' src={game.thumbnail} alt='' />
      <h3 className='text-lg font-medium mb-5 text-dark'>{game.title}</h3>
      <Button onClick={() => playGame.show({ game })} fullWidth size='md'>
        Play
      </Button>
    </div>
  )
}
