import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import LoginForm from 'features/login/LoginForm'
import AuthLayout from 'layout/AuthLayout'

export default function LoginPage() {

  return (
    <>
      <Helmet>
        <title>RME | Login</title>
      </Helmet>
      <AuthLayout
        title='Log In Your Account'
        description='Please provide correct username & password'
      >
        <LoginForm />
        <p className='mt-12 text-gray text-lg font-medium text-center'>
          {'Don’t have an account? Please'}{' '}
          <Link className='text-secondary' to='/signup'>
            click here
          </Link>{' '}
          to register
        </p>

      </AuthLayout>
    </>
  )
}
