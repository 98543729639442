import { AiOutlineUsergroupAdd } from 'react-icons/ai'
import { TreeNode } from 'react-organizational-chart'

export default function EmptyNode() {
  return (
    <TreeNode
      label={
        <div className='w-8 h-8 mx-auto bg-primary-shade grid place-items-center border border-dashed border-slate-400 rounded-full'>
          <AiOutlineUsergroupAdd size={15} color='green' />
        </div>
      }
    />
  )
}
