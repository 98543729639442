import { privateRequest } from 'config/axios.config'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export const getProfile = (params?: { onSuccess?: (data: User) => void }) =>
  useQuery<User, Error>(
    'get-profile',
    async () => {
      try {
        const res = await privateRequest.get('user/me')
        return res.data
      } catch (err) {
        return errorHandler(err)
      }
    },
    {
      onSuccess: (data) => {
        params?.onSuccess && params.onSuccess(data)
      },
    },
  )

export const getAvatar = () =>
  useQuery<Blob>('get-avatar', async () => {
    try {
      const res = await privateRequest.get('user/avatar', {
        responseType: 'blob',
      })
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  })
