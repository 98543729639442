import { useModal } from '@ebay/nice-modal-react'
import { BiBlock } from 'react-icons/bi'

import Button from 'components/form/Button'
import UpgradeInfoCardModal from 'features/academy/UpgradeInfoCard.modal'

export default function UnAuthorizedView() {
  const upgradeInfoCard = useModal(UpgradeInfoCardModal)
  return (
    <div className='flex flex-col items-center justify-center w-full p-7 text-white bg-[#091D45] h-[calc(100vh-400px)]'>
      <BiBlock size={100} />
      <p className='text-base font-semibold mt-4 mb-8'>
        Please Upgrade to associate for using AI Helper
      </p>
      <Button onClick={() => upgradeInfoCard.show()}>Upgrade Now</Button>
    </div>
  )
}
