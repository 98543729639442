import NoData from 'components/NoData'
import Skeleton from 'components/Skeleton'
import Select from 'components/form/Select'
import { privateRequest } from 'config/axios.config'
import BookCard from 'features/academy/bookCard'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export default function EBooksPage() {
  const { data: categories } = useQuery<Category[], Error>('ebook-categories', async () => {
    try {
      const res = await privateRequest.get('user/ebook_categories')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  const [selectedCategory, setSelectedCategory] = useState<Option>({ label: 'All', value: '' })

  const { data, isLoading } = useQuery<Book[], Error>(['ebooks', selectedCategory], async () => {
    try {
      const res = await privateRequest.get(
        `user/ebooks?${selectedCategory?.value ? `category_id=${selectedCategory.value}` : ''}`,
      )
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  const handleCategoryChange = (e: SelectElement) => {
    setSelectedCategory(e.target.value)
  }

  const categoryOptions =
    categories?.map((category) => ({
      label: category.category_name,
      value: String(category.id),
    })) || []

  return (
    <div className='card'>
      <div className='flex justify-end gap-5'>
        <Select
          align='right'
          placeholder='Select Category'
          options={[{ label: 'All', value: '' }, ...categoryOptions] || []}
          onChange={handleCategoryChange}
          value={selectedCategory}
        />
      </div>
      <div className='mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6'>
        {isLoading
          ? Array(5)
              .fill(0)
              .map((_, key) => (
                <div key={key}>
                  <Skeleton className='h-72 w-full' />
                  <Skeleton className='mt-4 h-3 w-20' />
                  <Skeleton className='h-4 w-3/4 mt-3' />
                </div>
              ))
          : data?.map((book: any) => <BookCard key={book.id} book={book} />)}
      </div>
      {!isLoading && !data?.length ? <NoData /> : null}
    </div>
  )
}
