import BreadCrumb from 'components/BreadCrumb'
import Devider from 'components/Devider'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'
import queryString from 'query-string'
import { SyntheticEvent, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

interface State {
  old_password: string
  new_password: string
  confirm_password: string
}

const initState = {
  old_password: '',
  new_password: '',
  confirm_password: '',
}

export default function ChangePasswordPage() {
  const [state, setState] = useState<State>(initState)

  const changeHandler = ({ target: { name, value } }: _ChangeHandlerEvent) => {
    setState((prev) => ({ ...prev, [name]: value }))
  }

  const { mutateAsync: changePassword, isLoading } = useMutation<
    { message: string },
    Error,
    string
  >(async (params) => {
    try {
      const res = await privateRequest.post(`user/change-password?${params}`)
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  })

  const submitHandler = (e: SyntheticEvent) => {
    e.preventDefault()

    const params = queryString.stringify(state)
    toast.promise(changePassword(params), {
      loading: 'Loading...',
      success: (res) => {
        setState(initState)
        return res.message ?? 'Successfully change password'
      },
      error: (err) => err.message ?? 'Error updating password',
    })
  }

  return (
    <div className='card'>
      <BreadCrumb prevPage='/security' title='Change Password' />
      <Devider />

      <form onSubmit={submitHandler} className='grid gap-3'>
        <Input
          name='old_password'
          value={state.old_password}
          onChange={changeHandler}
          type='password'
          placeholder='*****'
          label='Enter current password'
        />
        <Input
          name='new_password'
          value={state.new_password}
          onChange={changeHandler}
          type='password'
          placeholder='*****'
          label='Enter New Password'
        />
        <Input
          name='confirm_password'
          value={state.confirm_password}
          onChange={changeHandler}
          type='password'
          placeholder='*****'
          label='Enter confirm password'
        />
        <div className='mt-6 flex justify-end'>
          <Button disabled={isLoading} className='!px-10'>
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}
