import { PropsWithChildren } from 'react'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>

export default function Badge({ children, ...rest }: PropsWithChildren<Props>) {
  return (
    <button
      {...rest}
      className='cursor-pointer ml-3 inline-grid place-items-center h-7 w-7 rounded-lg bg-slate-100 transition-all hover:bg-slate-200 text-dark'
    >
      {children}
    </button>
  )
}
