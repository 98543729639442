import { AiOutlineMail } from 'react-icons/ai'
import { MdOutlinePassword } from 'react-icons/md'

import Devider from 'components/Devider'
import LinkTabs from 'components/LinkTabs'
import { securityTabs } from 'constants/securityTabs'
import SecurityTab from 'features/security/securityTab'

export default function SecurityPage() {
  return (
    <div className='card'>
      <LinkTabs options={securityTabs} selected={securityTabs[1]} />
      <Devider className='h-20' />
      <SecurityTab
        isDisable
        buttonText='Change'
        description='example@domain.com'
        title='Change Email'
        Icon={AiOutlineMail}
        link='/change-email'
      />
      <SecurityTab
        buttonText='Change'
        description='************'
        title='Change Password'
        Icon={MdOutlinePassword}
        link='/change-password'
      />
    </div>
  )
}
