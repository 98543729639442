import moment from 'moment'

export const getVideoId = (url: string) => {
  let videoId = ''
  videoId = url
    ?.replace('https://streams.bitmovin.com/', '')
    ?.replace('/embed', '')
    ?.replace('/share', '')
  return videoId
}

export const dateFormatter = (date: Date) => {
  return moment.utc(date).format('YYYY-MM-DD HH:mm:ss')
}
