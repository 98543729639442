type Props = React.SVGProps<SVGSVGElement>
export default function CWalletIcon({ width = 41, height = 46, ...rest }: Props) {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox='0 0 41 46'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M40.8835 32.1634V13.6735C40.8835 12.896 40.6789 12.1323 40.2901 11.459C39.9014 10.7857 39.3423 10.2266 38.669 9.83795L22.6563 0.593238C21.9829 0.204596 21.2192 0 20.4417 0C19.6643 0 18.9006 0.204596 18.2272 0.593238L2.21451 9.83795C1.54123 10.2267 0.982135 10.7858 0.593407 11.459C0.20468 12.1323 1.97523e-05 12.8961 2.31599e-09 13.6735V32.1634C-2.51373e-05 32.9408 0.204614 33.7046 0.593346 34.3778C0.982078 35.0511 1.5412 35.6102 2.21451 35.9989L18.2272 45.2436C18.9006 45.6323 19.6643 45.8369 20.4417 45.8369C21.2192 45.8369 21.9829 45.6323 22.6563 45.2436L38.669 35.9989C39.3423 35.6102 39.9014 35.0511 40.2901 34.3778C40.6789 33.7046 40.8835 32.9408 40.8835 32.1634Z'
        fill='currentColor'
      />
      <path
        d='M16.4854 26.3023V18.9163C16.4854 18.0689 16.7996 17.2433 17.4279 16.4397C18.2607 15.3731 19.3858 14.8325 20.803 14.8179V17.9081C20.1894 17.9227 19.7949 18.2077 19.6195 18.7629C19.5903 18.8213 19.5757 18.8725 19.5757 18.9163V26.3023C19.5757 26.5361 19.6999 26.7625 19.9483 26.9817C20.1821 27.1863 20.467 27.2885 20.803 27.2885V30.3788C19.1666 30.3788 17.9319 29.6921 17.0991 28.3186C16.69 27.6173 16.4854 26.9452 16.4854 26.3023ZM21.9866 30.3788V27.2885C21.9866 27.2885 22.1546 27.2228 22.4906 27.0913C22.6952 26.9452 22.834 26.7918 22.9071 26.631C22.9801 26.4411 23.0166 26.1708 23.0166 25.8201V25.5571L26.0412 26.2804C26.0412 27.5515 25.5298 28.6035 24.507 29.4364C23.7326 30.0647 22.8925 30.3788 21.9866 30.3788ZM21.9866 17.9081V14.8179C23.0532 14.8179 24.0175 15.2854 24.8796 16.2205C25.654 17.068 26.0412 17.9812 26.0412 18.9601L23.0166 19.7491V19.4204C23.0166 19.0551 22.9801 18.7848 22.9071 18.6095C22.8486 18.4634 22.739 18.3319 22.5783 18.215C22.4322 18.0981 22.3007 18.0177 22.1838 17.9739L21.9866 17.9081Z'
        fill='white'
      />
    </svg>
  )
}
