import Dropdown from 'components/Dropdown'
import Logo from 'components/Logo'
import { AuthContext } from 'context/AuthContext'
import brandIcon from 'images/ai.png'
import { getAvatar } from 'queries/auth'
import { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { CiImageOn } from 'react-icons/ci'
import { Link } from 'react-router-dom'
import { webAppUrl } from 'utils/url'

type Props = {
  title: string
  utcTime?: boolean
  aihelper?: boolean
}

export default function PageWrapper({
  title,
  utcTime,
  aihelper,
  children,
}: PropsWithChildren<Props>) {
  const { setDrawerShow, user } = useContext(AuthContext)
  const { data: avatar } = getAvatar()

  const [time, setTime] = useState('')
  useEffect(() => {
    const updateCounter = () => {
      const now = new Date()
      const targetDate = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1, 0, 0, 0, 0),
      )
      const remainingTime = targetDate.getTime() - now.getTime()

      if (remainingTime > 0) {
        const hours = Math.floor(remainingTime / (1000 * 60 * 60))
        const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000)

        setTime(
          `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`,
        )
      } else {
        setTime('00:00:00')
      }
    }

    updateCounter()
    const interval = setInterval(updateCounter, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div>
      <div className='bg-white flex lg:hidden justify-center pt-4'>
        <Logo />
      </div>
      <header className='bg-white flex flex-wrap gap-2 items-center px-3 sm:px-9 py-5 md:py-9'>
        <svg
          className='lg:hidden cursor-pointer'
          onClick={() => setDrawerShow(true)}
          stroke='currentColor'
          fill='currentColor'
          strokeWidth='0'
          viewBox='0 0 1024 1024'
          height='25'
          width='25'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z'></path>
        </svg>
        <h2 className='text-2xl font-medium hidden md:inline-block'>
          <div className='flex items-center'>
            {aihelper && <img src={brandIcon} className='h-10 w-10 mr-2' alt='IMG' />}
            {title}
            {utcTime && (
              <div className='ml-2' style={{ whiteSpace: 'nowrap' }}>
                <span className='text-sm fon text-gray font-medium'>
                  CUT-OFF: UTC Time{''}
                  <span className='text-dark text-lg font-semibold'>{time}</span>
                </span>
              </div>
            )}
          </div>
        </h2>

        <div className='ml-auto flex items-center gap-4'>
          <a target='_blank' className='btn btn-primary btn-md' href={webAppUrl} rel='noreferrer'>
            Visit Website
          </a>
          <Dropdown
            selector={
              <div className='flex items-center gap-2'>
                <p className='text-md max-w-[150px] whitespace-nowrap text-ellipsis overflow-hidden font-medium'>
                  {user?.username}
                </p>
                {avatar ? (
                  <img
                    src={URL.createObjectURL(avatar)}
                    className='h-10 w-10 rounded-full overflow-hidden object-cover bg-slate-500'
                    alt='IMG'
                  />
                ) : (
                  <div className='h-10 w-10 text-dark border-2 border-primary/60 rounded-full grid place-items-center'>
                    <CiImageOn />
                  </div>
                )}
              </div>
            }
            className='w-40 p-0'
            position='right'
          >
            <ul>
              <li className='p-2 hover:bg-slate-100'>
                <Link className='block' to='/security/profile'>
                  Profile
                </Link>
              </li>
            </ul>
          </Dropdown>
        </div>
      </header>
      <main className='p-4 md:p-9'>{children}</main>
    </div>
  )
}
