import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Modal from 'components/modal'
import { AuthContext } from 'context/AuthContext'
import PreeUpgradePlanModal from 'features/dashboard/PreeUpgradePlan.modal'
import { getAvatar } from 'queries/auth'
import { useContext } from 'react'

export default NiceModal.create(() => {
  const modal = useModal()

  const { user } = useContext(AuthContext)

  const { data: avatar } = getAvatar()
  const preeUpgradePlan = useModal(PreeUpgradePlanModal)

  return (
    <Modal
      backdrop={false}
      visible={modal.visible}
      onCancel={() => modal.hide()}
      className='max-w-sm shadow-lg'
    >
      <div className='flex items-center gap-4 mb-6'>
        {avatar ? (
          <img
            src={URL.createObjectURL(avatar)}
            className='h-16 w-16 rounded-full overflow-hidden object-cover bg-slate-500'
            alt='IMG'
          />
        ) : (
          <div className='h-16 w-16 rounded-full overflow-hidden bg-slate-500 grid place-items-center text-white uppercase'>
            {user?.name?.split(' ')?.map((r) => r?.[0])}
          </div>
        )}
        <div>
          <h2 className='text-2xl font-medium mb-2'>{user?.name}</h2>
          <h5 className='text-sm font-medium'>{user?.membership} Member</h5>
        </div>
      </div>
      <h3 className='text-lg italic font-semibold mb-3'>Upgrade to access</h3>
      <p className='font-medium text-gray text-xs mb-6'>
        To access the exclusive e-books. You need to upgrade your package
      </p>

      <Button
        onClick={() => {
          modal.remove()
          preeUpgradePlan.show()
        }}
        size='md'
        className='italic'
        fullWidth
      >
        Upgrade
      </Button>
    </Modal>
  )
})
