import { ImTree } from 'react-icons/im'

import clsx from 'clsx'
import Devider from 'components/Devider'
import { Loader } from 'components/Loader'
import NoData from 'components/NoData'
import Tabs from 'components/Tabs'
import Button from 'components/form/Button'
import { privateRequest } from 'config/axios.config'
import { AuthContext } from 'context/AuthContext'
import AffiliateTree from 'features/network/AffiliateTree'
import NetworkStats from 'features/network/NetworkStats'
import { useContext, useState } from 'react'
import { CiBoxList } from 'react-icons/ci'
import { HiOutlineUsers } from 'react-icons/hi2'
import { RxReset } from 'react-icons/rx'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'
import { appUrl } from 'utils/url'

const tabs: TabOption[] = [
  {
    label: <ImTree size={22} />,
    value: 'tree',
  },
  {
    label: <CiBoxList size={22} />,
    value: 'list',
  },
]

export default function NetworkPage() {
  const [selected, setSelected] = useState<TabOption>(tabs[0])
  const { user } = useContext(AuthContext)
  const [selectedTreeId, setSelectedTreeId] = useState<string>(String(user?.id))

  const { data: points } = useQuery<NetworkPoints, Error>('get-points', async () => {
    try {
      const res = await privateRequest.get('user/points-with-carry')
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  })

  const { data: tree, isLoading } = useQuery<NetworkNode, Error>(
    ['get-tree', selectedTreeId],
    async () => {
      try {
        const res = await privateRequest.get(`user/binary-tree/${selectedTreeId}/depth/3`)
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
  )
  const { data: treeList, isLoading: isLoadingTreeList } = useQuery<NetworkNode[], Error>(
    'get-tree-list',
    async () => {
      try {
        const res = await privateRequest.get(`user/binary-tree-list/${user?.id}`)
        return res.data.data
      } catch (err) {
        errorHandler(err)
      }
    },
  )

  return (
    <div className='card'>
      <div className='flex flex-wrap justify-between gap-6 items-center'>
        <Tabs options={tabs} selected={selected} selectHandler={(v) => setSelected(v)} />
        <Button to='/invite'>
          <HiOutlineUsers size={20} /> Refer
        </Button>
      </div>
      <Devider className='h-10' />
      {selected.value === 'tree' ? (
        <>
          {points && (
            <div className='mb-5 md:mb-0 flex gap-4 flex-wrap justify-center md:justify-between'>
              <NetworkStats data={points?.left_points} title='Left' />

              <NetworkStats data={points?.right_points} title='Right' />
            </div>
          )}
          {isLoading && (
            <div className='py-20'>
              <Loader />
            </div>
          )}
          <RxReset
            title='Go Back to Top Level'
            onClick={() => setSelectedTreeId(String(user?.id))}
            className={clsx('mx-auto mb-3 p-2 cursor-pointer', {
              'opacity-0 invisible': String(user?.id) === selectedTreeId,
            })}
            size={40}
          />
          {tree && <AffiliateTree data={tree} updateSelectedNode={(id) => setSelectedTreeId(id)} />}
        </>
      ) : (
        <div className='overflow-x-auto max-w-full'>
          <table>
            <thead>
              <tr>
                <td className='w-28'>Thumbnail</td>
                <td>Username</td>
              </tr>
            </thead>
            <tbody>
              {treeList?.map((tree, key) => (
                <tr key={key}>
                  <td>
                    <img
                      className='h-16 w-16 rounded-full object-cover'
                      src={`${appUrl}storage/avatars/` + tree.user_avatar}
                      alt=''
                    />
                  </td>
                  <td>{tree.username}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {isLoadingTreeList ? <Loader className='py-20' /> : !treeList?.length ? <NoData /> : null}
        </div>
      )}
    </div>
  )
}
