import { CiImageOn } from 'react-icons/ci'

import Button from 'components/form/Button'
import Input from 'components/form/Input'
import LinkTabs from 'components/LinkTabs'
import { privateRequest } from 'config/axios.config'
import { securityTabs } from 'constants/securityTabs'
import { getAvatar, getProfile } from 'queries/auth'
import { SyntheticEvent, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { BsInfoCircleFill } from 'react-icons/bs'
import { useMutation, useQueryClient } from 'react-query'

interface UpdateProfilePayload {
  name: string
}

export default function ProfilePage() {
  const { data } = getProfile()

  const [state, setState] = useState<UpdateProfilePayload>({
    name: '',
  })

  useEffect(() => {
    resetState()
  }, [data])

  const resetState = () => {
    setState((prev) => ({
      ...prev,
      name: data?.name ?? '',
    }))
  }

  const [avatarFile, setAvatarFile] = useState<File>()

  const avatarChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setAvatarFile(file)
    }
  }

  const queryClient = useQueryClient()

  const { data: avatar } = getAvatar()

  const updateProfile = useMutation<
    {
      message: string
      errors?: {
        avatar?: string[]
        name?: string[]
      }
    },
    Error,
    FormData
  >(
    async (payload) => {
      try {
        const res = await privateRequest.post('user/profile/update', payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        return res.data
      } catch (err: any) {
        return err.response.data
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('get-profile')
        queryClient.invalidateQueries('get-avatar')
      },
      onError: () => {
        resetState()
      },
    },
  )

  const submitHandler = (e: SyntheticEvent) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('name', state.name)
    if (avatarFile) {
      formData.append('avatar', avatarFile ?? '')
    }

    toast.promise(updateProfile.mutateAsync(formData), {
      loading: 'Loading...',
      success: (res) => {
        setAvatarFile(undefined)
        return res.message ?? 'Successfully updated profile'
      },
      error: (err) => err.message ?? 'Error updating profile',
    })
  }

  const changeHanlder = ({ target: { value, name } }: any) => {
    setState((prev) => ({ ...prev, [name]: value }))
  }

  const previewAvatar = avatarFile ?? avatar

  return (
    <div className='card'>
      <LinkTabs options={securityTabs} selected={securityTabs[0]} />

      <div className='mt-20 flex items-center gap-4'>
        <div className='grid place-items-center bg-slate-200 h-36 w-36 overflow-hidden rounded-xl'>
          {previewAvatar ? (
            <img
              className='h-full w-full object-cover'
              src={URL.createObjectURL(previewAvatar)}
              alt=''
            />
          ) : (
            <CiImageOn size={150} color='#ccc' />
          )}
        </div>
        <label className='btn btn-default btn-md cursor-pointer'>
          Change photo
          <input className='hidden' onChange={avatarChangeHandler} type='file' />
        </label>
      </div>

      <p className='text-red-500 mt-2'>
        {updateProfile.data?.errors?.avatar?.map((msg) => (
          <>
            {msg} <br />
          </>
        ))}
      </p>

      <form onSubmit={submitHandler} className='mt-10 grid grid-cols-1 gap-5'>
        <Input
          placeholder='Name'
          onChange={changeHanlder}
          name='name'
          value={state.name}
          label='Full Name'
          helpText={updateProfile.data?.errors?.name?.[0]}
        />
        <Input
          placeholder='Username'
          onChange={changeHanlder}
          name='username'
          value={data?.username}
          readOnly
          label='Username'
          afterFix={
            <div className='flex gap-2 items-center whitespace-nowrap pr-3 text-gray select-none'>
              <BsInfoCircleFill /> not changeable
            </div>
          }
        />
        <div className='flex justify-end'>
          <Button className='!px-10'>Update</Button>
        </div>
      </form>
    </div>
  )
}
