import clsx from 'clsx'
import { PropsWithChildren } from 'react'

type Props = {
  className?: string
}

export default function ChatItemCard({ children, className = '' }: PropsWithChildren<Props>) {
  return (
    <div
      className={clsx(
        'bg-[#F3F3F3] rounded-bl-2xl rounded-tr-2xl rounded-br-2xl p-4 flex items-center gap-2',
        className,
      )}
    >
      {children}
    </div>
  )
}
