import ecxchange from 'images/ecxchange.png';

export default function ExchangePage() {

  return (
    <div className='card text-center'>
      <img src={ecxchange} alt="" className='mx-auto mb-10' />
      <p className='text-[#7A7A7A] text-lg leading-9 max-w-6xl mx-auto'>{'RME Fincare\'s decentralized exchange allows users to trade cryptocurrencies and other digital assets efficiently. Our innovative technology facilitates peer-to-peer trading without a central authority, which means lower fees, enhanced security, and increased privacy for our users. Ahead of the upcoming bend lies an intriguing horizon brimming with abundant opportunities.'}</p>
    </div>
  )
}
