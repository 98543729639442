import ComingSoongSection from 'components/ComingSoongSection'
import RequireAuth from 'components/auth/RequireAuth'
import { AuthContext } from 'context/AuthContext'
import PageWrapper from 'layout/PageWrapper'
import { useContext, useEffect, useState } from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'
import PageNotFound from './404'
import AiHelperPage from './ai-helper'
import ChangeEmailPage from './change-email'
import ChangePasswordPage from './change-password'
import CoursesPage from './courses'
import Dashboard from './dashboard'
import EBooksPage from './ebooks'
import ExchangePage from './exchange'
import ForgotPasswordPage from './forgot-password'
import GeneralDisclaimer from './general-disclaimer'
import InvitePage from './invite'
import LoginPage from './login'
import MarketingToolsPage from './marketing-tools'
import NetworkPage from './network'
import PlayPage from './play'
import ProfilePage from './profile'
import ResetPasswordPage from './reset-password'
import SecurityPage from './security'
import RegisterPage from './signup'
import SingleBookPage from './single-book'
import SingleCoursePage from './single-course'
import StakingPage from './staking'
import VerifyOtpPage from './verify-otp'
import WalletPage from './wallet'

const privateRoute: RouteObject[] = [
  {
    path: '/',
    element: <RequireAuth />,
    children: [
      { index: true, element: <Navigate to='dashboard' /> },
      {
        path: 'dashboard',
        element: (
          <PageWrapper title='Dashboard'>
            <Dashboard />
          </PageWrapper>
        ),
      },
      {
        path: 'wallet/r-wallet',
        element: (
          <PageWrapper title='R-Wallet'>
            <WalletPage walletName='r-wallet' />
          </PageWrapper>
        ),
      },
      {
        path: 'wallet/c-reward',
        element: (
          <PageWrapper title='C-Reward'>
            <WalletPage walletName='c-reward' />
          </PageWrapper>
        ),
      },
      {
        path: 'wallet/s-reward',
        element: (
          <PageWrapper title='S-Reward'>
            <WalletPage walletName='s-reward' />
          </PageWrapper>
        ),
      },
      {
        path: 'security/profile',
        element: (
          <PageWrapper title='Security'>
            <ProfilePage />
          </PageWrapper>
        ),
      },
      {
        path: 'security',
        element: (
          <PageWrapper title='Security'>
            <SecurityPage />
          </PageWrapper>
        ),
      },
      {
        path: 'change-email',
        element: (
          <PageWrapper title='Security'>
            <ChangeEmailPage />
          </PageWrapper>
        ),
      },
      {
        path: 'change-password',
        element: (
          <PageWrapper title='Security'>
            <ChangePasswordPage />
          </PageWrapper>
        ),
      },
      {
        path: 'network',
        element: (
          <PageWrapper utcTime title='Network'>
            <NetworkPage />
          </PageWrapper>
        ),
      },
      {
        path: 'invite',
        element: (
          <PageWrapper title='Network'>
            <InvitePage />
          </PageWrapper>
        ),
      },
      {
        path: 'academy/ebooks',
        element: (
          <PageWrapper title='Books'>
            <EBooksPage />
          </PageWrapper>
        ),
      },
      {
        path: 'academy/courses',
        element: (
          <PageWrapper title='Courses'>
            <CoursesPage />
          </PageWrapper>
        ),
      },
      {
        path: 'academy/course/:id',
        element: (
          <PageWrapper title=''>
            <SingleCoursePage />
          </PageWrapper>
        ),
      },
      {
        path: 'academy/book/:id',
        element: (
          <PageWrapper title=''>
            <SingleBookPage />
          </PageWrapper>
        ),
      },
      {
        path: 'academy/podcasts',
        element: (
          <PageWrapper title='Podcasts'>
            <ComingSoongSection />
          </PageWrapper>
        ),
      },
      {
        path: 'play',
        element: (
          <PageWrapper title='Play'>
            <PlayPage />
          </PageWrapper>
        ),
      },
      {
        path: 'fincare',
        element: (
          <PageWrapper title='Fincare'>
            <ComingSoongSection />
          </PageWrapper>
        ),
      },
      {
        path: 'wellness',
        element: (
          <PageWrapper title='Wellness'>
            <ComingSoongSection />
          </PageWrapper>
        ),
      },

      {
        path: 'music',
        element: (
          <PageWrapper title='Music'>
            <ComingSoongSection />
          </PageWrapper>
        ),
      },

      {
        path: 'fincare/exchange',
        element: (
          <PageWrapper title='Exchange'>
            <ExchangePage />
          </PageWrapper>
        ),
      },
      {
        path: 'fincare/staking',
        element: (
          <PageWrapper title='Staking'>
            <StakingPage />
          </PageWrapper>
        ),
      },
      {
        path: 'tools',
        element: (
          <PageWrapper title='Tools'>
            <ComingSoongSection />
          </PageWrapper>
        ),
      },
      {
        path: 'tools/marketing',
        element: (
          <PageWrapper title='Marketing Tools'>
            <MarketingToolsPage />
          </PageWrapper>
        ),
      },
      {
        path: 'tools/ai-helper',
        element: (
          <PageWrapper aihelper title='Ai Helper'>
            <AiHelperPage />
          </PageWrapper>
        ),
      },
      { path: 'login', element: <Navigate to='/' /> },
      { path: 'signup', element: <Navigate to='/' /> },
      { path: '*', element: <PageNotFound /> },
    ],
  },
]

const publicRoute: RouteObject[] = [
  { path: 'general-disclaimer', element: <GeneralDisclaimer /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'signup', element: <RegisterPage /> },
  { path: 'forgot-password', element: <ForgotPasswordPage /> },
  { path: 'verify-otp', element: <VerifyOtpPage /> },
  { path: 'reset-password', element: <ResetPasswordPage /> },
  { path: '*', element: <Navigate to='/login' /> },
]

const Routes = () => {
  const { token } = useContext(AuthContext)

  const [routes, setRoutes] = useState<RouteObject[]>(token ? privateRoute : publicRoute)

  // change routes on token state
  useEffect(() => {
    setRoutes(token ? privateRoute : publicRoute)
  }, [token])

  const appRoutes = useRoutes(routes)

  if (token) {
    return <div>{appRoutes}</div>
  }

  return <div>{appRoutes}</div>
}

export default Routes
