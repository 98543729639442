import { AuthContext } from 'context/AuthContext'
import Layout from 'layout/Layout'
import { getProfile } from 'queries/auth'
import { useContext } from 'react'
import { LoaderIcon } from 'react-hot-toast'
import { Outlet } from 'react-router-dom'
import UnAuthorized from './UnAuthorized'

const RequireAuth = () => {
  const { setUser, user } = useContext(AuthContext)

  const { isLoading } = getProfile({
    onSuccess: (data) => {
      setUser(data)
    },
  })

  if (isLoading) {
    return (
      <div className='h-screen flex'>
        <LoaderIcon className='m-auto w-16 h-16' />
      </div>
    )
  }

  if (!user) {
    return <UnAuthorized />
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export default RequireAuth
