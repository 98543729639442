import { TreeNode } from 'react-organizational-chart'
import EmptyNode from './EmptyNode'
import NodeImage from './NodeImage'

interface Props {
  data: NetworkNode
  updateSelectedNode: (id: string) => void
}

export default function TreeList({ data, updateSelectedNode }: Props) {
  if (!data) {
    return null
  }

  return (
    <TreeNode label={<NodeImage data={data} updateSelectedNode={updateSelectedNode} />}>
      {data.left_child ? (
        <TreeList
          key={data.left_child.id}
          data={data.left_child}
          updateSelectedNode={updateSelectedNode}
        />
      ) : (
        <EmptyNode />
      )}

      {data.right_child ? (
        <TreeList
          key={data.right_child.id}
          data={data.right_child}
          updateSelectedNode={updateSelectedNode}
        />
      ) : (
        <EmptyNode />
      )}
    </TreeNode>
  )
}
