import Alert from 'components/Alert'
import Skeleton from 'components/Skeleton'
import { privateRequest } from 'config/axios.config'
import MarketingToolsCard from 'features/tools/MarketingToolsCard'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export default function MarketingToolsPage() {
  const { data, isLoading, isError, error } = useQuery<Tool[], Error>(['tools'], async () => {
    try {
      const res = await privateRequest.get('user/marketing-tools')
      return res.data
    } catch (error) {
      errorHandler(error)
    }
  })

  if (isError) {
    return <Alert message={error.message ?? 'Something went wrong!'} />
  }

  return (
    <div className='card'>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6'>
        {isLoading
          ? Array(10)
              .fill(0)
              .map((_, key) => (
                <div key={key}>
                  <Skeleton className='w-full h-40 mb-4' />
                  <Skeleton className='w-full h-4 mb-4' />
                  <Skeleton className='w-full h-9' />
                </div>
              ))
          : data?.map((tool) => <MarketingToolsCard key={tool.id} tool={tool} />)}
      </div>
    </div>
  )
}
