import queryString from 'query-string'
import { SyntheticEvent, useContext, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { Link, useLocation } from 'react-router-dom'

import Button from 'components/form/Button'
import CheckboxInput from 'components/form/CheckboxInput'
import Input from 'components/form/Input'
import InputAfterFixShowHider from 'components/form/InputAfterFixShowHider'
import { privateRequest } from 'config/axios.config'
import { AuthContext } from 'context/AuthContext'
import AuthLayout from 'layout/AuthLayout'

type Form = {
  name: string
  username: string
  password: string
  email: string
  sponsor: string
  placement: string
}

export default function RegisterPage() {
  const { search } = useLocation()
  const ref = new URLSearchParams(search).get('ref')

  const [disclaimerIsChecked, setDisclaimerIsChecked] = useState<boolean>(false)

  const { setToken } = useContext(AuthContext)
  const [form, setForm] = useState<Form>({
    name: '',
    username: '',
    password: '',
    email: '',
    sponsor: '',
    placement: '',
  })

  useEffect(() => {
    if (ref) {
      setForm({
        ...form,
        sponsor: ref,
      })
    }
  }, [ref])

  const [errors, setErrors] = useState<{
    name?: string
    username?: string
    password?: string
    email?: string
    sponsor?: string
    placement?: string
  }>({})

  const signupMutation = useMutation<{ token: string; message: string }, Error, string>(
    async (payload) => {
      const res = await privateRequest.post(`user-registration?${payload}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        if (!data.token) return
        localStorage.setItem('token', data.token)
        setToken(data.token)
      },
    },
  )

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

  const changeHandler = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })

    setErrors({
      ...errors,
      [e.target.name]: undefined,
    })
  }

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    if (!form.name || !form.username || !form.password || !form.email) {
      setErrors({
        name: !form.name ? 'Name is required' : undefined,
        username: !form.username ? 'Username is required' : undefined,
        password: !form.password ? 'Password is required' : undefined,
        email: !form.email ? 'Email is required' : undefined,
      })
      return
    }

    const payload = form as any
    Object.keys(payload).forEach(
      (key) => (payload[key] == null || payload[key] == '') && delete payload[key],
    )

    const payloadString = queryString.stringify(payload)

    toast.promise(signupMutation.mutateAsync(payloadString), {
      loading: 'Creating user...',
      success: (res) => res.message || 'User created successfully',
      error: (err) => {
        setErrors(err.response.data?.errors)
        return err.response.data.message || 'Failed to signup'
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>RME | Signup</title>
      </Helmet>
      <AuthLayout
        title='Sign Up For New Account'
        description='Please use the correct information for registration'
      >
        <form className='flex flex-col gap-6' onSubmit={onSubmit}>
          <Input
            onChange={changeHandler}
            value={form.name}
            label='Name'
            name='name'
            placeholder='Enter your name'
            helpText={errors.name}
          />
          <Input
            onChange={changeHandler}
            value={form.username}
            label='Username'
            name='username'
            placeholder='Enter your username'
            helpText={errors.username}
          />
          <Input
            onChange={changeHandler}
            value={form.email}
            label='Email'
            info='You can use a single email for multiple account'
            name='email'
            type='email'
            placeholder='Enter your email'
            helpText={errors.email}
          />
          <Input
            onChange={changeHandler}
            value={form.password}
            label='Password'
            name='password'
            placeholder='Enter your password'
            type={isPasswordVisible ? 'text' : 'password'}
            helpText={errors.password}
            afterFix={
              <InputAfterFixShowHider
                isVisible={isPasswordVisible}
                onClick={() => setIsPasswordVisible((prev) => !prev)}
                type='eye'
              />
            }
          />
          <Input
            readOnly={!!ref}
            onChange={changeHandler}
            value={form.sponsor}
            label='Sponsor (Optional)'
            name='sponsor'
            placeholder='Enter your sponsor'
            helpText={errors.sponsor}
          />
          <Input
            onChange={changeHandler}
            value={form.placement}
            label='Placement (Optional)'
            name='placement'
            placeholder='Enter your placement'
            helpText={errors.placement}
          />

          <div className='flex justify-between'>
            <div className='flex items-center gap-2'>
              <CheckboxInput
                name='disclaimer'
                checked={disclaimerIsChecked}
                onChange={(e) => setDisclaimerIsChecked(e.target.checked)}
              />
              <a
                target='_blank'
                className='whitespace-nowrap text-secondary hover:underline'
                href='https://rewardsmadeeasy.io/general-disclaimer/'
                rel='noreferrer'
              >
                General Disclaimer
              </a>
            </div>
            <Button disabled={!disclaimerIsChecked} className='!px-10'>
              Register
            </Button>
          </div>
        </form>
        <p className='mt-12 text-gray text-lg font-medium text-center'>
          Already have an account? Please{' '}
          <Link className='text-secondary' to='/login'>
            click here
          </Link>{' '}
          to login
        </p>
      </AuthLayout>
    </>
  )
}
