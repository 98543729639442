import copy from 'copy-to-clipboard'
import { useContext, useState } from 'react'
import { toast } from 'react-hot-toast'
import { BsLink45Deg } from 'react-icons/bs'

import BreadCrumb from 'components/BreadCrumb'
import Devider from 'components/Devider'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Title from 'components/Title'
import { privateRequest } from 'config/axios.config'
import { AuthContext } from 'context/AuthContext'
import { useMutation } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export default function InvitePage() {
  const { user } = useContext(AuthContext)

  const [email, setEmail] = useState<string>('')

  const inviteMutation = useMutation<Message, Error, FormData>(async (payload) => {
    try {
      const res = await privateRequest.post('user/send-invitation', payload)
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  })

  const invite = () => {
    if (!email) return toast.error('Email is required')
    if (!email.includes('@')) return toast.error('Invalid email address')

    const payload = new FormData()
    payload.append('recipient_email', email)

    toast.promise(inviteMutation.mutateAsync(payload), {
      loading: 'Sending...',
      success: (res) => res.message ?? 'Successfully sent',
      error: (err) => err.message ?? 'Something went wrong',
    })
  }

  const refLink = `${window.location.origin}/signup?ref=${user?.username ?? ''}`

  return (
    <div className='card'>
      <BreadCrumb prevPage='/network' title='Network' />
      <Devider />

      <Title>Refer & Get Rewards</Title>
      <p className='text-gray text-sm mt-1'>
        You will receive 1 USDT in C-Rewards and 5 USDT in your R-Wallet for each friend who joins
        and purchases an Associate Member package using your referral link.
      </p>

      <div className='grid grid-cols-1 xl:grid-cols-3 gap-5 mt-8'>
        <div className='xl:col-span-2 card !bg-primary-shade'>
          <Title variant='card_title' className='mb-3'>
            Send Email Invites
          </Title>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='Enter email Address'
            inputClassName='bg-white'
            afterFix={<Button onClick={invite}>Invite</Button>}
          />
          {/* <Title variant='card_title' className='mt-8'>
            Invited
          </Title>

          <div className='flex items-center gap-5 text-[#68799C] mt-6'>
            <span className='font-semibold'>email@example.com</span>
            <span className='ml-auto font-medium'>Waiting</span>
          </div> */}
        </div>
        <div>
          <div className='card !bg-primary-shade'>
            <Title className='mb-3' variant='card_title'>
              Your Invite Link
            </Title>
            <Input value={refLink} inputClassName='bg-white' readOnly />
            <Button
              onClick={() => {
                toast.success('Successfully copied')
                copy(refLink)
              }}
              className='w-full mt-3'
            >
              <BsLink45Deg size={25} /> Copy Link
            </Button>
          </div>

          {/* <div className='card !bg-primary-shade mt-5'>
            <p className='flex items-center flex-wrap gap-1'>
              <strong className='text-3xl font-medium'>0</strong> Friends Joined on your Networks
            </p>
            <p className='mt-5 flex items-center flex-wrap gap-1'>
              <strong className='text-3xl font-medium'>0</strong> Friends registered Associate
              membership
            </p>
          </div> */}
        </div>
      </div>
    </div>
  )
}
