import { useModal } from '@ebay/nice-modal-react'
import copy from 'copy-to-clipboard'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { BsSearch } from 'react-icons/bs'
import { FiCopy } from 'react-icons/fi'

import Badge from 'components/Badge'
import Devider from 'components/Devider'
import { Loader } from 'components/Loader'
import NoData from 'components/NoData'
import Skeleton from 'components/Skeleton'
import Status from 'components/Status'
import Title from 'components/Title'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import transferCrewardModal from 'features/wallet/transfer-creward.modal'
import transferSrewardModal from 'features/wallet/transfer-sreward.modal'
import withdrawModal from 'features/wallet/withdraw.modal'
import creward from 'images/c-reward.png'
import rwallet from 'images/r-wallet.png'
import sreward from 'images/s-reward.png'
import { getBalances, getTransactions } from 'queries/walletQuires'
import { dateFormatter } from 'utils'
import { StatusColorFinder } from 'utils/StatusColorFinder'

type SortByOptions = { label: string; value: TransactionSourceType }

interface WalletFilter {
  search: string
  sortby: SortByOptions
}

const sortbyOptions: SortByOptions[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Dual Team',
    value: 'dual_team',
  },
  {
    label: 'Signup',
    value: 'signup',
  },
  {
    label: 'Direct Referral',
    value: 'direct_referral',
  },
]

interface Props {
  walletName: WalletType
}

export default function WalletPage({ walletName }: Props) {
  const withdraw = useModal(withdrawModal)
  const transferCreward = useModal(transferCrewardModal)
  const transferSreward = useModal(transferSrewardModal)
  const [filters, setFilters] = useState<WalletFilter>({
    search: '',
    sortby: sortbyOptions[0],
  })

  const { data: balances, isLoading: isLoadingBalances } = getBalances()

  const { data: transactions, isLoading } = getTransactions(
    filters.sortby.value,
    walletName,
    filters.search,
  )

  const changeHandler = ({ target: { name, value } }: any) => {
    setFilters((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <div>
      <div className='card flex flex-wrap items-center  gap-10'>
        <div className='flex-3'>
          {walletName == 'c-reward' && <img src={creward} />}
          {walletName == 's-reward' && <img src={sreward} />}
          {walletName == 'r-wallet' && <img src={rwallet} />}
        </div>
        <div className='flex-auto'>
          <div className='flex justify-between mb-2 gap-10'>
            <h5 className='text-base uppercase'>{walletName} Balance</h5>
          </div>
          <h2 className='text-4xl font-semibold'>
            {isLoadingBalances || !balances ? (
              <Skeleton className='h-6 w-28' />
            ) : (
              <>
                {walletName === 'c-reward' && balances['c-reward_balance']}
                {walletName === 's-reward' && balances['s-reward_balance']}
                {walletName === 'r-wallet' && <>{balances['r-wallet_balance']}</>}
              </>
            )}
          </h2>

          <p className='font-medium mt-2 text-gray text-base'>
            {walletName === 'c-reward' && <> ≈ {balances?.['c-reward_balance']} USDT</>}
            {walletName === 's-reward' && <> ≈ {balances?.['s-reward_balance']} USDT</>}
            {walletName === 'r-wallet' && <> ≈ {balances?.['r-wallet_balance']} USDT</>}
          </p>
        </div>
        <div className='flex gap-4'>
          {walletName === 'c-reward' && (
            <Button
              size='lg'
              onClick={() =>
                transferCreward.show({ balance: balances?.['c-reward_balance'] ?? '0.00' })
              }
            >
              Transfer
            </Button>
          )}
          {walletName === 's-reward' && (
            <Button
              size='lg'
              onClick={() =>
                transferSreward.show({ balance: balances?.['s-reward_balance'] ?? '0.00' })
              }
            >
              Transfer
            </Button>
          )}
          {walletName === 'r-wallet' && (
            <Button
              size='lg'
              onClick={() => withdraw.show({ balance: balances?.['r-wallet_balance'] ?? '0.00' })}
            >
              Withdraw
            </Button>
          )}
        </div>
      </div>
      <Devider />
      <Title className='mb-4'>Latest Transactions</Title>
      <div className='card'>
        <div className='flex gap-5 flex-wrap mb-6 justify-end items-center'>
          <Input
            name='search'
            value={filters.search}
            onChange={changeHandler}
            afterFix={<BsSearch size={20} className='mr-3 text-gray' />}
            placeholder='Search TxID'
            className='!w-auto'
          />
          <Select
            className='!min-w-[200px]'
            name='sortby'
            value={filters.sortby}
            onChange={changeHandler}
            align='right'
            options={sortbyOptions}
          />
        </div>
        <table>
          <thead>
            <tr>
              <td>TXID</td>
              <td>Timestamp</td>
              <td>Amount</td>
              <td>Note</td>
              <td align='right'>Status</td>
            </tr>
          </thead>
          <tbody>
            {transactions?.map((row) => (
              <tr key={row.id}>
                <td>
                  {row.reference}
                  <Badge
                    onClick={() => {
                      copy(row.reference)
                      toast.success('Copied')
                    }}
                  >
                    <FiCopy />
                  </Badge>
                </td>
                <td>{dateFormatter(row.created_at)}</td>
                <td>
                  {row.type === 'credit' ? (
                    <span className='text-green-500'>+</span>
                  ) : (
                    <span className='text-red-500'>-</span>
                  )}{' '}
                  {row.amount}
                </td>
                <td>{row.note}</td>
                <td align='right'>
                  <Status color={StatusColorFinder(row.status)}>{row.status}</Status>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading ? <Loader className='py-28' /> : !transactions?.length ? <NoData /> : null}
      </div>
    </div>
  )
}
