import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useContext, useState } from 'react'

import clsx from 'clsx'
import Button from 'components/form/Button'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { AuthContext } from 'context/AuthContext'
import creward from 'images/c-reward.png'
import external from 'images/external.png'
import { getProfile } from 'queries/auth'
import { getStatistics } from 'queries/dashboard'
import { toast } from 'react-hot-toast'
import { AiOutlineCheck } from 'react-icons/ai'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'
import UpgradePlanModal from './UpgradePlan.modal'

export default NiceModal.create(() => {
  const modal = useModal()

  const { data } = getStatistics()

  const queryClient = useQueryClient();

  const paymentMethods = [
    {
      label: 'C-Reward',
      value: 'c-reward',
      img: creward,
      desc: `Available Balance ${data?.c_reward_balance}`,
    },
    // {
    //   label: 'S-Reward',
    //   value: 's-reward',
    //   img: sreward,
    //   desc: `Available Balance ${data?.s_reward_balance}`,
    // },
    {
      label: 'External',
      value: 'external',
      img: external,
      desc: 'Support 13 different coins',
    },
  ]

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<Option>(paymentMethods[0])

  const upgradePlan = useModal(UpgradePlanModal)

  const { setUser } = useContext(AuthContext)

  const { refetch } = getProfile({
    onSuccess: (data) => {
      setUser(data)
    },
  })

  const { isLoading, mutateAsync } = useMutation<
    UpgradeMembership,
    Error,
    'c-reward' | 's-reward' | 'external'
  >(
    async (payment_method) => {
      try {
        const res = await privateRequest.post(
          'user/upgrade-membership',
          {
            package_id: 1,
            payment_method,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
        )
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries('get-statistics')
        if (variables === 'c-reward' || variables === 's-reward') {
          refetch()
          modal.remove()
        } else {
          // upgradePlan.show({ data })
          window.location.href = data.charge.hosted_url;
          modal.remove()
        }
      },
    },
  )

  const handleUpgrade = () => {
    toast.promise(mutateAsync(selectedPaymentMethod.value as any), {
      loading: 'Creating charge...',
      success: (res) => res.message ?? 'Success',
      error: (err) => err.message ?? 'Something went wrong',
    })
  }

  return (
    <Modal
      title='Choose Payment Method'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-2xl'
    >
      <div className='grid sm:grid-cols-2 gap-6 mt-4'>
        {paymentMethods.map((method) => (
          <div
            key={method.value}
            className={`rounded-md flex justify-between items-center gap-4 p-6 border-2 border-primary-shade cursor-pointer ${selectedPaymentMethod.value === method.value ? 'bg-gray-100' : 'bg-white'
              }`}
            onClick={() => setSelectedPaymentMethod(method)}
          >
            <div>
              <img src={method.img} alt={method.label} />
              <h4 className='my-3 text-base font-medium'>{method.label}</h4>
              <p className='text-slate-500 font-xs'>{method.desc}</p>
            </div>
            <div
              className={clsx(
                'grid place-items-center border-2 transition-all border-primary w-7 h-7 rounded-full text-white',
                {
                  'bg-primary': selectedPaymentMethod.value === method.value,
                },
              )}
            >
              {selectedPaymentMethod.value === method.value && <AiOutlineCheck size={16} />}
            </div>
          </div>
        ))}
      </div>

      <Button
        disabled={isLoading}
        onClick={handleUpgrade}
        variant='outlined'
        color='default'
        size='md'
        className='py-6 mt-10'
        fullWidth
      >
        Confirm
      </Button>
    </Modal>
  )
})
