import { Loader } from 'components/Loader'
import NoData from 'components/NoData'
import Status from 'components/Status'
import { getTransactions } from 'queries/walletQuires'
import { dateFormatter } from 'utils'
import { StatusColorFinder } from 'utils/StatusColorFinder'

export default function LastActivity() {
  const { data: transactions, isLoading } = getTransactions('All', '', '', 10)
  return (
    <div className='card min-h-[400px] max-w-full overflow-y-auto'>
      <table>
        <thead>
          <tr>
            <td>TXID</td>
            <td>Timestamp</td>
            <td>Amount</td>
            <td>Note</td>
            <td align='right'>Status</td>
          </tr>
        </thead>
        <tbody>
          {transactions?.map((row) => (
            <tr key={row.id}>
              <td>{row.reference}</td>
              <td>{dateFormatter(row.created_at)}</td>
              <td>
                {row.type === 'credit' ? (
                  <span className='text-green-500'>+</span>
                ) : (
                  <span className='text-red-500'>-</span>
                )}{' '}
                {row.amount}
              </td>
              <td>{row.note}</td>
              <td align='right'>
                <Status color={StatusColorFinder(row.status)}>{row.status}</Status>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading ? <Loader className='mt-40' /> : !transactions?.length ? <NoData /> : null}
    </div>
  )
}
