type Props = React.SVGProps<SVGSVGElement>
export default function EbookIcon({ width = 20, height = 16, ...rest }: Props) {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox='0 0 20 16'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.0294 15.5136H0.97037C0.437751 15.5136 0.00427246 15.0804 0.00427246 14.5475V4.74185C0.00427246 4.20911 0.437751 3.77563 0.97037 3.77563C1.47721 3.77563 2.05893 3.66559 2.05893 4.08806V12.4542C2.05893 12.7378 2.28967 12.9687 2.57338 12.9687L4.45214 12.9721C5.26425 13.0929 6.97121 13.3624 8.73267 14.2417C9.86119 14.805 10.1389 14.8058 11.267 14.2419C13.0398 13.3557 14.7273 13.0986 15.5478 12.9721L17.4266 12.9687C17.7102 12.9687 17.9411 12.7378 17.9411 12.4542V4.08806C17.9411 3.66548 18.5229 3.77563 19.0296 3.77563C19.5622 3.77563 19.9957 4.20911 19.9957 4.74185V14.5477C19.9955 15.0804 19.5621 15.5136 19.0295 15.5136H19.0294ZM11.3696 14.8889H19.0294C19.2176 14.8889 19.3709 14.7359 19.3709 14.5475V4.74185C19.3709 4.32091 18.8772 4.40036 18.5656 4.40036V12.4542C18.5656 13.0823 18.0545 13.5933 17.4264 13.5933H15.618C15.3804 13.6306 13.3199 13.9647 12.1235 14.52L11.3696 14.8888V14.8889ZM0.970487 4.40036C0.782283 4.40036 0.629001 4.55364 0.629001 4.74185V14.5477C0.629001 14.7358 0.782166 14.889 0.970487 14.889L8.63024 14.8889L7.87661 14.5202C6.67871 13.9643 4.61949 13.6304 4.38183 13.5934L2.57327 13.5933C1.94514 13.5933 1.43432 13.0822 1.43432 12.4542V4.40036H0.970487Z'
        fill='currentColor'
      />
      <path
        d='M10.0597 15.2836C9.19791 15.2836 8.72482 14.914 7.87662 14.5203C6.67872 13.9644 4.61949 13.6305 4.38184 13.5935H2.57328C1.94515 13.5935 1.43433 13.0824 1.43433 12.4544V3.7708C1.43433 3.18415 1.88644 2.71681 2.5339 2.63419C6.68821 2.10438 9.04334 3.8956 9.78772 3.77947C9.97803 3.74982 10.1488 3.89701 10.1488 4.08791V14.3276C10.8977 13.7498 14.0041 11.2957 14.557 10.9747C14.8726 10.7915 14.9583 10.72 14.9583 10.5656V2.86622C14.9583 2.70087 15.087 2.56423 15.2521 2.55439C15.9708 2.51126 16.7154 2.5381 17.4661 2.63407C18.1135 2.71657 18.5657 3.18404 18.5657 3.77068V12.4543C18.5657 13.0826 18.0546 13.5935 17.4265 13.5935L15.6182 13.5936C15.3803 13.6309 13.32 13.965 12.1235 14.5203C11.4036 14.8542 10.7549 15.2838 10.0595 15.2838L10.0597 15.2836ZM4.07363 3.15908C3.59726 3.15908 3.10578 3.19095 2.61289 3.25388C2.33714 3.28904 2.05894 3.46247 2.05894 3.7708V12.4544C2.05894 12.738 2.28968 12.9689 2.57339 12.9689L4.45215 12.9723C5.26192 13.0927 6.9731 13.3629 8.73267 14.2419C9.04533 14.3982 9.29201 14.5215 9.52393 14.5922V4.39377C8.47814 4.24705 6.95048 3.15919 4.07363 3.15919V3.15908ZM15.5829 3.16458V10.5656C15.5829 11.264 14.9371 11.4232 14.5623 11.7119L11.3109 14.2201C13.0499 13.3526 14.7935 13.0884 15.5477 12.9723L17.4265 12.9689C17.7101 12.9689 17.941 12.738 17.941 12.4544V3.7708C17.941 3.46247 17.6627 3.28904 17.387 3.25388C16.7761 3.17572 16.1746 3.14572 15.5829 3.16458Z'
        fill='currentColor'
      />
      <path
        d='M9.83623 15.2755C9.66501 15.2755 9.5238 15.137 9.5238 14.9632V4.08815C9.5238 3.98689 9.57279 3.89209 9.65529 3.8335L14.1905 0.610826C14.8242 0.16059 15.5828 1.00739 15.5828 1.76431V10.5657C15.5828 11.2641 14.9368 11.4233 14.5622 11.7119L10.027 15.2107C9.97232 15.2528 9.90524 15.2756 9.83623 15.2756V15.2755ZM10.1488 4.24928V14.3277C10.8977 13.7499 14.0041 11.2958 14.557 10.9748C14.8726 10.7916 14.9583 10.7202 14.9583 10.5657V1.76431C14.9583 1.4554 14.647 1.05227 14.5527 1.12012L10.1488 4.2494V4.24928Z'
        fill='currentColor'
      />
    </svg>
  )
}
