import { privateRequest } from 'config/axios.config'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export const getStatistics = () =>
  useQuery<StatisticsResponse, Error>('get-statistics', async () => {
    try {
      const res = await privateRequest.get('user/statictics')
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  })
