export default function GeneralDisclaimer() {
  return (
    <div className='card container mx-auto m-5'>
      <h2 className='mb-2'>RME LLC General Disclaimer</h2>
      <p>{`If you require more information or have any questions about our site's
        disclaimer, please feel free to contact us by email at
        support@rewardsmadeeasy.io.`}</p>
      <br />
      <br />

      <h3 className='mb-2'>Disclaimers for RME LLC</h3>
      <ol>
        <li>
          Every effort has been made to represent our programs and educational value accurately.
          However, there is no guarantee that you will make money using the techniques and ideas in
          these materials. When we present revenue and sales figures on our website and other
          channels, we highlight exceptional results that do not reflect the average experience. You
          should not rely on any income, sales, or earnings information we provide as a promise,
          guarantee, or expectation of any level of success or earnings. Your results will be
          determined by several factors over which we have no control, such as your financial
          situation, experiences, skills, level of effort, education, and market changes. You use
          any information on this website at your own risk. We provide content without express or
          implied warranties, subject to our Refund Policy. By using our site and accessing our
          content, you agree that we are not responsible for any decisions you make based on the
          information presented or as a result of purchasing any of our products or services. Any
          claims of actual earnings or examples of actual results can be verified upon request.
        </li>
        <li>{`RME LLC, Inc makes no representations or warranties about this
          information's completeness, reliability, or accuracy. Any action
          you take based on the information on this website is entirely at
          your own risk. RME LLC will not be liable for any losses or
          damages from using our website.`}</li>
        <li>
          You can visit other websites by following hyperlinks to such external sites from our
          website. While we make every effort to provide only high-quality links to useful and
          ethical websites, we have no control over the content or nature of these websites. These
          links to other websites do not imply endorsement of the content on those sites. Site
          owners and content may change without notice and before we can remove a potentially
          harmful link.
        </li>
        <li>{`Please be aware that when you leave our website, other
          websites may have different privacy policies and terms that are
          beyond our control. Before conducting any transaction or
          uploading any data, kindly review these websites' "Terms of
          Service" and Privacy Policies.`}</li>
      </ol>
      <br />
      <br />
      <h4 className='mb-2'>Consent</h4>
      <p>Using our website, you consent to our disclaimer and agree to its terms.</p>
      <br />
      <br />
      <h4 className='mb-2'>Update</h4>
      <p>
        If we update, amend or make any changes to this document, those changes will be prominently
        posted here.
      </p>
    </div>
  )
}
