import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/page-navigation/lib/styles/index.css'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { zoomPlugin } from '@react-pdf-viewer/zoom'
import Alert from 'components/Alert'
import BreadCrumb from 'components/BreadCrumb'
import Devider from 'components/Devider'
import { Loader } from 'components/Loader'
import { privateRequest } from 'config/axios.config'
import { useEffect, useState } from 'react'
import { errorHandler } from 'utils/errorHandler'

import { Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/toolbar/lib/styles/index.css'
import clsx from 'clsx'

export default function SingleBookPage() {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, isError, error } = useQuery<
    Book & { success: false; message: string },
    Error
  >(
    ['ebook', id],
    async () => {
      try {
        const res = await privateRequest.get(`user/ebook/${id}`)
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      enabled: id !== undefined,
    },
  )

  const { data: binary } = useQuery(
    ['binary', id],
    async () => {
      try {
        const res = await privateRequest.get(`user/ebookProtect/${id}`, {
          responseType: 'blob',
          headers: {
            Accept: 'application/pdf',
          },
        })
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      enabled: id !== undefined,
    },
  )

  const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const url = URL.createObjectURL(new Blob([binary], { type: 'application/pdf' }))
    setPdfUrl(url)
  }, [binary])

  const zoomPluginInstance = zoomPlugin()
  const { Zoom, ZoomIn, ZoomOut } = zoomPluginInstance
  const pageNavigationPluginInstance = pageNavigationPlugin()
  const {
    CurrentPageInput,
    GoToFirstPageButton,
    GoToLastPageButton,
    GoToNextPageButton,
    GoToPreviousPage,
  } = pageNavigationPluginInstance

  if (isLoading) return <Loader className='h-64 flex items-center' />
  if (isError) return <Alert message={error.message} />

  return (
    <div className='card'>
      <BreadCrumb prevPage={'/academy/ebooks'} title={'Ebook List'} />
      <Devider />
      <div className='flex justify-between gap-10 items-center mb-6'>
        <div>
          <h3 className='text-base font-medium text-dark'>{data?.book_title}</h3>
          <h6 className='text-xs font-medium text-gray mt-2'>by {data?.book_author}</h6>
        </div>
      </div>
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
        <div className='bg-primary-shade min-h-[200px]'>
          {data?.book_file_url ? (
            <div>
              <div className='p-2 bg-slate-50 mb-2 flex flex-wrap md:flex-nowrap justify-between items-center'>
                <div className='flex items-center'>
                  <GoToFirstPageButton />
                  <GoToPreviousPage />
                  <CurrentPageInput />
                  <GoToNextPageButton />
                  <GoToLastPageButton />
                </div>
                <div className='flex items-center'>
                  <ZoomOut />
                  <Zoom />
                  <ZoomIn />
                </div>
              </div>
              {isLoaded ? null : <Loader className='h-64 flex items-center' />}
              <div
                className={clsx('h-[calc(100vh-250px)] p-2', {
                  'opacity-0': !isLoaded,
                })}
              >
                <Viewer
                  renderLoader={() => <Loader className='h-64 flex items-center' />}
                  onDocumentLoad={() => setIsLoaded(true)}
                  defaultScale={1}
                  plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
                  fileUrl={pdfUrl ?? ''}
                />
              </div>
            </div>
          ) : (
            <>No PDF</>
          )}
        </div>
      </Worker>
    </div>
  )
}
