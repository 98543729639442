type Props = {
  title: string
  data: NetworkPointObj
}

export default function NetworkStats({ data, title }: Props) {
  return (
    <div className='border border-slate-200 bg-white'>
      <h3 className='bg-slate-300 text-center text-sm font-semibold py-2'>{title}</h3>
      <ul className='py-2'>
        <li className='px-3 py-1 grid grid-cols-2 gap-5'>
          <span className='text-gray'>Today</span> <span>{data.points} Reward Points</span>
        </li>
        <li className='px-3 py-1 grid grid-cols-2 gap-5'>
          <span className='text-gray'>Carry-Over</span> <span>{data.carry} Reward Points</span>
        </li>
        <li className='px-3 py-1 grid grid-cols-2 gap-5'>
          <span className='text-gray'>Total</span> <span>{data.total} Reward Points</span>
        </li>
      </ul>
    </div>
  )
}
