import copy from 'copy-to-clipboard'
import { useContext } from 'react'
import { toast } from 'react-hot-toast'
import { IoCopy } from 'react-icons/io5'

import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Title from 'components/Title'
import { AuthContext } from 'context/AuthContext'

export default function Refer() {
  const { user } = useContext(AuthContext)
  const refLink = `${window.location.origin}/signup?ref=${user?.username ?? ''}`
  return (
    <div className='card'>
      <Title variant='card_title'>Invite & get reward</Title>
      <p className='text-xs text-gray mt-2'>Join your friends and get instant reward</p>
      <Input
        readOnly
        afterFix={
          <Button
            className='!px-2'
            onClick={() => {
              toast.success('Successfully copied')
              copy(refLink)
            }}
          >
            <IoCopy />
            Copy
          </Button>
        }
        className='mt-6'
        value={refLink}
        placeholder={refLink}
      />
    </div>
  )
}
